import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export enum StatusColor {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  CLOSED = "Closed",
  HOLD = "Hold",
}

const projectStatusForgroundColorsMap = new Map();
projectStatusForgroundColorsMap.set(StatusColor?.ACTIVE, "#027A48");
projectStatusForgroundColorsMap.set(StatusColor?.INACTIVE, "#AAAAAA");
projectStatusForgroundColorsMap.set(StatusColor?.CLOSED, "#F15046");
projectStatusForgroundColorsMap.set(StatusColor?.HOLD, "#F5C330");

const projectStatusBackgroundColorsMap = new Map();
projectStatusBackgroundColorsMap.set(StatusColor?.ACTIVE, "#ECFDF3");
projectStatusBackgroundColorsMap.set(StatusColor?.INACTIVE, "#EEEEEE");
projectStatusBackgroundColorsMap.set(StatusColor?.CLOSED, "#FFF2EA");
projectStatusBackgroundColorsMap.set(StatusColor?.HOLD, "#FFFEDD");

interface AvatarChipsProps {
  color: StatusColor;
  label: string;
}

const CustomChip = styled(Chip)<{ chipColor: string }>(({ chipColor }) => ({
  backgroundColor: chipColor,
  color: "#fff",
  position: "relative",
  "& .MuiAvatar-root": {
    backgroundColor: "#fff",
    color: chipColor,
  },
}));

const AvatarChips: React.FC<AvatarChipsProps> = ({ label }) => {
  const mappedColor = projectStatusBackgroundColorsMap.get(label);

  const forGroundMappedColor = projectStatusForgroundColorsMap.get(label);

  return (
    <Stack direction="row" spacing={1}>
      <CustomChip
        sx={{
          color: forGroundMappedColor,
          fontWeight: "var(--font-weight-600)",
        }}
        size="small"
        avatar={
          <Box
            style={{
              marginLeft: "10px",
              backgroundColor: forGroundMappedColor,
              width: "6px",
              height: "6px",
              borderRadius: "50%",
            }}
          ></Box>
        }
        label={label}
        chipColor={mappedColor}
      />
    </Stack>
  );
};

export default AvatarChips;
