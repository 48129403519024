import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import { Grid, Typography } from "@mui/material";
import DetailsCard from "../DetailsCard";
import { Column, AssistoTable } from "../../../../widgets/Table";
import { Stack } from "@mui/system";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ThemeProvider } from "@emotion/react";
import theme from "../../../../widgets/Theme/theme";
import { ITableData } from "../..";

interface IDashboardTable{
  tableData: ITableData[]
}

const DashBoardTable = (props:IDashboardTable) => {
  const { t } = useTranslation(NAMESPACE.HOME);
const{tableData}= props

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={3}
        mt={1}
        px={{ lg: 4, xl: 0 }}
        pr={{ lg: 0, xl: 4 }}
      >
        {tableData.map((data: ITableData, index: number) => (
          <Grid item xs={12} sm={12} md={10} lg={6} key={index}>
            <Stack py={4} px={2} className="hire-assisto__white-bg">
              <div>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h3" pt={1} pb={4}>
                    {t(data.title)}
                  </Typography>
                  <OpenInNewIcon />
                </Stack>
              </div>

              <AssistoTable
                rows={data.tableRows}
                columns={data.tableColumn}
                headerFontSize={data.headerFontSize}
                defaultMessage={t(data.defaultMessage)}
              />
            </Stack>
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  );
};
export default DashBoardTable;
