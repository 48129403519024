import { FULL_TIME } from "../../../utils/constants";
import { IFields } from "./interface";
// import { JobDescription, ShortJobDescription } from "./interface";



export const initialCreateJdState: IFields= {
    job_posting_type: FULL_TIME,
    domain: "india",
    jobTitleField: "",
    companyName: null,
    status: "Active",
    industry: null,
    category: null,
    roles: null,
    job_end_date: null,
    vacancy: null,
    notice_period: "",
    jobSummary: null,
    jobDescription: null,
    fromExpField: 0,
    toExpField: 0,
    certifiedDegree: null,
    specialization: null,
    minSalary: "",
    maxSalary: "",
    currency: null,
    name: "",
    phoneNo: null,
    email: "",
    aboutCompany: "",
    onlineUrl: null,
    venue: null,
    country: null,
    client: null,
    state: null,
    city: null,
    selectedSkills: null,
    fromTiming: null,
    toTiming: null,
    fromWalkDate: null,
    toWalkDate: null,
    joiningDate: null,
    contractTimeperiod: "",
    billingRate: "PER_YEAR",
    skillsItemData: [],
    subskillData: [],
    mustHaveSkill: [],
    goodToHaveSkill: [],
    jd_skills_details: [],
    selectedSkillData: [],
    is_template: false,
    templateNameField: "",
    id: undefined
}
// export const shortJobDescriptionInitialValues: ShortJobDescription = {
//     ...initialCreateJdState,
//     skillsItemData: undefined,
//     subskillData: undefined,
//     selectedSkillData: undefined
// };