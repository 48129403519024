import { Stack, Typography } from "@mui/material";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface IfileCard {
  fileName: string;
  fileType: string;
  fileSize: string;
  index: number;
  handleRemoveFile: (index: number) => void;
}

function FileCard(props: IfileCard) {
  const { fileName, fileType, fileSize, handleRemoveFile, index } = props;

  return (
      <Stack
        direction={"row"}
        my={3}
        justifyContent={"space-between"}
        alignItems={"center"}
        pr={4}
      >
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <ContactPageOutlinedIcon fontSize="large" />
          <Stack direction={"column"}>
            <Typography
              className="text-overflow"
              variant="h6"
              fontSize={"var(--font-size-12)"}
              fontWeight={"var(--font-weight-600)"}
            >
              {fileName}
            </Typography>
            <Stack direction={"row"} gap={2}>
              <Typography
                fontSize={"var(--font-size-12)"}
                className="size-overflow"
                variant="h6"
              >
                {fileType}
              </Typography>
              <Typography fontSize={"var(--font-size-12)"} variant="h6">
                {fileSize}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <CloseOutlinedIcon onClick={() => handleRemoveFile(index)} />
      </Stack>
  );
}

export default FileCard;
