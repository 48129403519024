import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UpdateJdThunk, IUpdateJdResponse } from "../thunks/updateJdThunk";
import { RootState } from "../store/store";
// import { setState } from "./createJdReducer";
import { act } from "react-dom/test-utils";
import { Root } from "react-dom/client";

export interface IupdateJdFormdata{
    formData: IUpdateJdResponse;
    loading: string;
}
const initialState: IupdateJdFormdata={
    formData:{
        status_message:"",
        data:{
            id:0,
            update_status:"",

        },
    },
    loading:"",
};

export const UpdateJdSlice = createSlice({
    name: "updateJd",
    initialState,
    reducers:{
        setUpdateState:(state, action: PayloadAction<IUpdateJdResponse>)=>{
            state.formData = action.payload;
        },
        resetUpdateJdState:(state)=>{
            return initialState
        },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(UpdateJdThunk.pending, (state)=>{
            state.loading="pending";
        })
        .addCase(UpdateJdThunk.fulfilled,(state, action:PayloadAction<IUpdateJdResponse>)=>{
            state.loading="succeeded";
            state.formData= action.payload;
        })
        .addCase(UpdateJdThunk.rejected, (state)=>{
            state.loading="failed";
        })
    }
});

export default UpdateJdSlice.reducer;
export const {setUpdateState, resetUpdateJdState}= UpdateJdSlice.actions;
export const updateJDinfo= (state:RootState)=> state.updateJd.formData;
export const updateJdApiLoading = (state:RootState)=>state.updateJd.loading;