export enum StatuscardType{
    Success = "success",
    Inprogress = "InProgress",
    Error = "error"
}   

export enum ButtonVarient{
    Text= 'text',
    Outlined= "outlined",
    Contained= "contained"
}