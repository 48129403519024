import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface ItotalFileCount {
  status_message: string;
  data: ItotalResumeData;
}

export interface ItotalResumeData {
  total_jobs_count: number;
  active_jobs_count: number;
  inactive_jobs_count: number;
  hold_jobs_count: number;
  closed_jobs_count: number;
  resumes_count: number;
}

const TotalResumeCountEndPoint = "job-descriptions/count";

export const callUploadResumeCount = createAsyncThunk(
  "totalFiles/count",
  async (thunkAPI) => {
    try {
      const response = await Get<ItotalResumeData, any>(
        TotalResumeCountEndPoint,
        undefined,
        {},
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
