import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { initialCreateJdState } from "../Components/initialvalue";
import {
  BillingRateItems,
  MenuItems,
  RegionItems,
  StatusItems,
  NoticePeriodItems,
} from "../Components/dropdown";

import { createOrUpdateJD } from "../Components/api";
import {
  validateJobTitle,
  validateCompanyName,
  validateVacancy,
  validateJobSummary,
  validateJobDescription,
  validateExperience,
  validateSkills,
  validateSalaryRange,
  validateCertifiedDegree,
  validateSpecialization,
  validateContactName,
  validatePhoneNumber,
  validateEmail,
  validateAboutCompany,
  validateOnlineUrl,
  validateContractFields,
  validateWalkInDriveFields,
  validateTemplateFields,
  validateCountry,
  validateJobEndDate,
} from "../Components/validation";

import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import SchoolIcon from "@mui/icons-material/School";
import PaidIcon from "@mui/icons-material/Paid";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import DrawRoundedIcon from "@mui/icons-material/DrawRounded";
import ContactsIcon from "@mui/icons-material/Contacts";
import AssistantIcon from "@mui/icons-material/Assistant";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import { ThemeProvider } from "styled-components";
import theme from "../../../widgets/Theme/theme";

import DropDownitems, { IMenus } from "../../../widgets/DropDownItems";
import AssistoTextFiled from "../../../widgets/AssistoTextField";
import AssistoDatePicker from "../../../widgets/AssistoDatePicker";
import AssistoCustomAddInputField from "../../../widgets/AssistoCustomAddInputField";
import AssistoTimePicker from "../../../widgets/AssistoTimePicker";
import { AssistoButton } from "../../../widgets/AssitoButton";
import SnackBar, { Status, Variant } from "../../../widgets/Toaster";
import AssistoModal from "../../../widgets/Modal";
import TextEditor from "../../../widgets/TextEditor";

import { AppDispatch, RootState } from "../../../store/store";

import {
  createJdThunk,
  ILocationInfoResponse,
  IStateInfoResponse,
  StateLocationToken,
  CityLocationToken,
  ICityInfoResponse,
  IIndustryData,
  ICategoryData,
  IRolesData,
  ISpecializationData,
  ISkillData,
  ICurrencyResponse,
  IEducationData,
  RolesThunkToken,
  SpecializationThunk,
  IClientData,
  IClientDetail,
} from "../../../thunks/homeThunk";

import {
  countryData,
  stateData,
  cityData,
  industryData,
  categoryData,
  rolesData,
  certifiedDegreeData,
  specializationData,
  skillData,
  currencyData,
  ClientData,
} from "../../../reducers/homeReducer";

import {
  alphaNumericWithSpaceOnlySpecialCharPattern,
  alphabetsWithFiveThousandChar,
  alphabetsWithTwoThousandChar,
  alphanumericWithSpaceAndDotChar,
  digitsOnly,
  experienceFieldWithDecimalChar,
  onlineUrl,
  onlyAlphabetsWithMaxFifteenChar,
  mobileNumberPattern,
  validEmailPattern,
  onlyNumbersAboveZeroAndMaxFourDigits,
  alphanumericSnakeCase,
  alphabetsNumbersWithDotAndHashAndSpace,
} from "../../../utils/regExp";

import { NAMESPACE } from "../../../utils/i18nUtils";
import { CONTRACT, FULL_TIME, WALK_IN_DRIVE } from "../../../utils/constants";
import { ButtonVarient } from "../../../utils/commonEnums";
import { Errors, Item } from "../../../utils/commonInterfaces";

import dayjs, { Dayjs } from "dayjs";
import { CSSProperties } from "styled-components";
import { jobsPage } from "../../Routes/routes";
import { handleSelectChangeUtil } from "../Components/selectUtils";

/*
 * purpose: Creating Normal Job Description
 * author: Aayush Chourasia
 * version: 1.0
 * lastUpdatedAt: 28-10-2024
 */

const saveTemplateModalStyle: CSSProperties = {
  position: "absolute",
  transform: "translate(50%, -50%)",
  top: "50%",
  right: "50%",
  width: 800,
  maxHeight: 600,
  borderRadius: "8px",
  backgroundColor: "#fff",
  padding: 3,
};

export interface ISkills {
  skill: string;
  experience_required_for_skill: number;
  ismandatory: boolean;
  extras: Extras;
}

export interface Extras {}

/*
 *The IFields interface defines the structure for job description (JD) creation in our application. It serves as a comprehensive blueprint for the essential data required to create and manage job postings. By encapsulating all relevant attributes, the interface ensures type safety and clarity in the handling of job-related information throughout the application.
 */

function JobDescription() {
  const initialErrorJdState = {
    jobTitleField: {
      mandatory: false,
      patternFail: false,
    },
    companyName: {
      patternFail: false,
    },
    jobEndDate: {
      mandatory: false,
    },
    vacancy: {
      patternFail: false,
    },
    fromExpField: {
      mandatory: false,
      patternFail: false,
      lessThan: false,
    },
    toExpField: {
      mandatory: false,
      patternFail: false,
      greaterThan: false,
    },
    certifiedDegree: {
      mandatory: false,
    },
    specialization: {
      mandatory: false,
    },
    mustHaveSkill: {
      mandatory: false,
      patternFail: false,
    },
    minSalary: {
      patternFail: false,
      minSalary: false,
    },
    maxSalary: {
      patternFail: false,
      maxSalary: false,
    },
    joiningDate: {
      mandatory: false,
    },
    currency: {
      mandatory: false,
    },
    country: {
      mandatory: false,
    },
    name: {
      mandatory: false,
      patternFail: false,
    },
    phoneNo: {
      patternFail: false,
    },
    email: {
      mandatory: false,
      patternFail: false,
    },
    onlineUrl: {
      patternFail: false,
    },
    billingRate: {
      mandatory: false,
      patternFail: false,
    },
    contractTimeperiod: {
      mandatory: false,
      patternFail: false,
    },
    venue: {
      mandatory: false,
      patternFail: false,
    },
    fromWalkDate: {
      mandatory: false,
    },
    toWalkDate: {
      mandatory: false,
    },
    fromTiming: {
      mandatory: false,
    },
    toTiming: {
      mandatory: false,
      patternFail: false,
    },
    aboutCompany: {
      patternFail: false,
    },
    jobSummary: {
      patternFail: false,
    },
    jobDescription: {
      patternFail: false,
    },
    templateNameField: {
      mandatory: false,
      patternFail: false,
    },
  };

  // const [formValues, setFormValues] = useState(initialCreateJdState)
  const [createJdPage, setCreateJdPage] = useState(initialCreateJdState);

  const location = useLocation();
  const jobDetails = location.state;
  const [errors, setErrors] = useState<Errors>(initialErrorJdState);
  const [toaster, setToaster] = useState({
    open: false,
    message: "",
    status: Status.Success,
    variant: Variant.Filled,
  });
  const [selectedSkillData, setSelectedSkillData] = useState<ISkillData[]>();
  const [saveTemplate, setSaveTemplate] = useState<boolean>(false);
  const { t } = useTranslation(NAMESPACE.CREATE_JD);
  const dispatch: AppDispatch = useDispatch();
  const countryItems: ILocationInfoResponse[] = useSelector(countryData);
  const stateItems: IStateInfoResponse[] = useSelector(stateData);
  const cityItems: ICityInfoResponse[] = useSelector(cityData);
  const { totalClients, client }: IClientData = useSelector(ClientData);
  const industryItemsData: IIndustryData[] = useSelector(industryData);
  const categoryItemsData: ICategoryData[] = useSelector(categoryData);
  const rolesItemsData: IRolesData[] = useSelector(rolesData);
  const degreeItemsData: IEducationData[] = useSelector(certifiedDegreeData);
  const specializationItemsData: ISpecializationData[] =
    useSelector(specializationData);

  const skillsItemData: ISkillData[] = useSelector(skillData);
  const currencyItemData: ICurrencyResponse[] = useSelector(currencyData);
  const navigate = useNavigate();

  useEffect(() => {
    if (createJdPage.minSalary === "" && createJdPage.maxSalary === "") {
      setCreateJdPage((prev) => {
        return { ...prev, currency: null };
      });
      setErrors((prev) => {
        return {
          ...prev,
          currency: {
            mandatory: false,
          },
        };
      });
    }
  }, [createJdPage.minSalary, createJdPage.maxSalary]);

  // For 'Industry' Select
  const handleIndustryChange = (value: string) => {
    const result = handleSelectChangeUtil(
      "industry",
      value,
      industryItemsData,
      "industry_name"
    );
    setCreateJdPage((prev) => ({
      ...prev,
      ...result,
    }));
  };

  // For 'Client' Select
  const handleClientChange = (value: string) => {
    const result = handleSelectChangeUtil(
      "client",
      value,
      client,
      "clientName"
    );
    setCreateJdPage((prev) => ({
      ...prev,
      ...result,
    }));
  };

  // For 'Category' Select
  const handleCategoryChange = (value: string) => {
    handleSelectChange(
      "category",
      {
        target: { id: "category", name: "category", value },
      } as SelectChangeEvent<string>,
      categoryItemsData,
      "category_name",
      RolesThunkToken
    );
  };

  // For 'Roles' Select
  const handleRolesChange = (value: string) => {
    const result = handleSelectChangeUtil(
      "roles",
      value,
      rolesItemsData,
      "role_name"
    );
    setCreateJdPage((prev) => ({
      ...prev,
      ...result,
    }));
  };

  // For 'Certified Degree' Select
  const handleCertifiedDegreeChange = (value: string) => {
    handleSelectChange(
      "certifiedDegree",
      {
        target: { id: "certifiedDegree", name: "certifiedDegree", value },
      } as SelectChangeEvent<string>,
      degreeItemsData,
      "education_degree_name",
      SpecializationThunk
    );

    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        certifiedDegree: {
          mandatory: false,
          patternFail: false,
        },
      };
    });
  };

  // For 'Specialization' Select
  const handleSpecializationChange = (value: string) => {
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        specialization: {
          mandatory: false,
          patternFail: false,
        },
      };
    });

    const result = handleSelectChangeUtil(
      "specialization",
      value,
      specializationItemsData,
      "specialization_name"
    );
    setCreateJdPage((prev) => ({
      ...prev,
      ...result,
    }));
  };

  // For 'Currency' Select
  const handleCurrencyChange = (value: string) => {
    const result = handleSelectChangeUtil(
      "currency",
      value,
      currencyItemData,
      "cc"
    );
    setCreateJdPage((prev) => ({
      ...prev,
      ...result,
    }));
  };
  // For 'Country' Select
  const handleCountryChange = (value: string) => {
    handleSelectChange(
      "country",
      {
        target: { id: "country", name: "country", value },
      } as SelectChangeEvent<string>,
      countryItems,
      "name",
      StateLocationToken
    );
  };

  // For 'State' Select
  const handleStateChange = (value: string) => {
    const result = handleSelectChange(
      "state",
      {
        target: {
          id: "state",
          name: "state",
          value,
        },
      } as SelectChangeEvent<string>,
      stateItems,
      "name",
      CityLocationToken
    );
  };

  // For 'City' Select
  const handleCityChange = (value: string) => {
    const result = handleSelectChangeUtil("city", value, cityItems, "name");
    setCreateJdPage((prev) => ({
      ...prev,
      ...result,
    }));
  };

  useEffect(() => {
    const goodToHaveSkills = skillsItemData.filter(
      (skill: any) => !createJdPage.mustHaveSkill.includes(skill.skill)
    );
    setSelectedSkillData(goodToHaveSkills);
  }, [createJdPage.mustHaveSkill]);
  useEffect(() => {
    if (jobDetails) {
      const JdDetails = jobDetails.job_details;
      const JdSkillDetails = jobDetails.jd_skills_details;
      const selectedIndustry = industryItemsData.find(
        (i) => i.industry_name === JdDetails.industry
      );
      const selectedCategory = categoryItemsData.find(
        (i) => i.category_name === JdDetails.category
      );
      const selectedRoles = rolesItemsData.find(
        (i) => i.role_name === JdDetails.roles
      );
      console.log(selectedRoles, "selected rolessss");
      const selectedNoticeperiod = JdDetails.notice_period;
      const selecteddegreeData = degreeItemsData.find(
        (i) => i.education_degree_name === JdDetails.certified_degree_name
      );
      const selectedCountry = countryItems.find(
        (i) => i.name === JdDetails.country
      );

      const selectedCurrency = currencyItemData.find(
        (i) => i.cc === JdDetails.currency
      );
      const selectedmusthaveskills = JdSkillDetails?.map((i: any) =>
        i.ismandatory === true ? i.skill : ""
      ).filter(Boolean);
      const selectedgoodtohaveskills = JdSkillDetails?.map((i: any) =>
        i.ismandatory === false ? i.skill : null
      ).filter(Boolean);
      console.log("Roles being sent:", rolesItemsData);
      console.log("Specialization being sent:", specializationItemsData);
      setCreateJdPage((prev) => ({
        ...prev,
        jobTitleField: JdDetails.job_title || "",
        companyName: JdDetails.company_name || "",
        status: JdDetails.status || "",
        industry: selectedIndustry || null,
        category: selectedCategory || null,
        roles: selectedRoles || null,
        notice_period: selectedNoticeperiod || "",
        job_end_date: JdDetails.job_end_date
          ? dayjs(JdDetails.job_end_date)
          : null,
        vacancy: JdDetails.vacancy || "",
        jobSummary: JdDetails.job_summary || "",
        jobDescription: JdDetails.job_description || null,
        fromExpField: JdDetails.minimum_experience
          ? Number(JdDetails.minimum_experience)
          : 0,
        toExpField: JdDetails.maximum_experience || 0,
        mustHaveSkill: selectedmusthaveskills || [],
        goodToHaveSkill: selectedgoodtohaveskills || [],
        country: selectedCountry || null,
        minSalary: JdDetails.minimum_salary || 0,
        maxSalary: JdDetails.maximum_salary || 0,
        currency: selectedCurrency || null,
        name: JdDetails.name || "",
        phoneNo: JdDetails.phone_number || 0,
        email: JdDetails.email || "",
        aboutCompany: JdDetails.about_company || "",
        onlineUrl: JdDetails.apply_online_url || "",
      }));
    }
  }, [jobDetails]);

  useEffect(() => {
    if (jobDetails) {
      const selectedRoles = rolesItemsData.find(
        (i) => i.role_name === jobDetails.job_details.roles
      );
      setCreateJdPage((previousData) => ({
        ...previousData,
        roles: selectedRoles || null,
      }));
    }
  }, [rolesItemsData]);

  useEffect(() => {
    const selectedCategory = categoryItemsData.find(
      (i) => i.category_name === jobDetails?.jd_details?.category
    );
    let catId;
    if (selectedCategory) {
      catId = selectedCategory.id;
    }
    if (jobDetails && catId !== undefined) {
      handleSelectChange(
        "category",
        {
          target: {
            id: "category",
            name: "category",
            value: jobDetails.job_details.category,
          },
        } as SelectChangeEvent<string>,
        categoryItemsData,
        "category_name",
        RolesThunkToken
      );
    }
  }, [categoryItemsData]);

  useEffect(() => {
    const selecteddegreeData = degreeItemsData.find(
      (i) => i.education_degree_name === jobDetails?.jd_details?.degrees
    );
    let degId;
    if (selecteddegreeData) {
      degId = selecteddegreeData.id;
    }
    if (jobDetails && degId !== undefined) {
      handleSelectChange(
        "certifiedDegree",
        {
          target: {
            id: "certifiedDegree",
            name: "certifiedDegree",
            value: jobDetails.job_details.certified_degree_name,
          },
        } as SelectChangeEvent<string>,
        degreeItemsData,
        "education_degree_name",
        SpecializationThunk
      );
    }
  }, [degreeItemsData]);

  useEffect(() => {
    if (jobDetails) {
      const selectedSpecialization = specializationItemsData.find(
        (i) => i.specialization_name
      );
      setCreateJdPage((previousData) => ({
        ...previousData,
        specialization: selectedSpecialization || null,
      }));
    }
  });

  useEffect(() => {
    if (jobDetails !== undefined && countryItems.length > 0) {
      handleSelectChange(
        "country",
        {
          target: {
            id: "country",
            name: "country",
            value: jobDetails?.job_details?.country,
          },
        } as SelectChangeEvent<string>,
        countryItems,
        "name",
        StateLocationToken
      );
    }
  }, [countryItems, jobDetails]);

  useEffect(() => {
    if (jobDetails !== undefined && jobDetails?.job_details.state) {
      handleSelectChange(
        "state",
        {
          target: {
            id: "state",
            name: "state",
            value: jobDetails?.job_details?.state,
          },
        } as SelectChangeEvent<string>,
        stateItems,
        "name",
        CityLocationToken
      );
    }
  }, [stateItems, jobDetails]);

  useEffect(() => {
    if (jobDetails && createJdPage.state) {
      const selectedCity = cityItems.find(
        (city) => city.name === jobDetails.job_details.city
      );
      if (selectedCity) {
        setCreateJdPage((prev) => ({
          ...prev,
          city: selectedCity,
        }));
      }
    }
  }, [jobDetails, cityItems, createJdPage.state]);

  const handleFieldChange = (
    e:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLTextAreaElement>
      | ChangeEvent<HTMLInputElement>,
    id: string,
    isMandatory: boolean,
    regex?: RegExp
  ) => {
    /*
     * handleFieldChange : Updates the state for a specific field when the user types into an input or textarea.
     * It validates the input against mandatory requirements and a provided regex pattern, as well as checking
     * if the value meets certain numerical conditions related to experience and salary.
     */

    const value = e.target.value;
    setCreateJdPage((prevValue) => ({
      ...prevValue,
      [id]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        mandatory: isMandatory ? value === "" : false,
        patternFail: value !== "" ? !regex?.test(value) : false,
        greaterThan: Number(value) < createJdPage?.fromExpField,
        maxSalary:
          createJdPage.minSalary !== null
            ? Number(value) < Number(createJdPage?.minSalary)
            : false,
      },
    }));
  };

  const handleTextEditor = (
    content: string,
    id: string,
    isMandatory: boolean,
    regex?: RegExp
  ) => {
    /*
     * handleTextEditor : Similar to `handleFieldChange`, but specifically for a text editor component.
     * It updates the state with the editor's content and performs the same validations as above.
     */
    setCreateJdPage((prevState) => ({
      ...prevState,
      [id]: content,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        mandatory: isMandatory ? content === "" : false,
        patternFail: content !== "" ? !regex?.test(content) : false,
        greaterThan: Number(content) < createJdPage?.fromExpField,
        maxSalary:
          createJdPage.minSalary !== null
            ? Number(content) < Number(createJdPage?.minSalary)
            : false,
      },
    }));
  };

  const handleSelectChange = <T extends Item>(
    fieldId: string,
    e: SelectChangeEvent<string>,
    data: T[] | undefined,
    itemKey: string,
    dispatchAction?: any
  ) => {
    /*
     * handleSelectChange : Manages changes to select fields. It updates the state with the selected item and,
     * if applicable, dispatches actions to update related data (e.g., cities based on selected states).
     */
    const selectedValue = e.target.value;
    let selectedItem: any = data?.find(
      (d: any) => d[itemKey] === selectedValue
    );
    if (selectedItem !== null && dispatchAction) {
      if (fieldId == "state") {
        dispatch(
          CityLocationToken({
            countryId: createJdPage.country?.id ?? "",
            stateId: selectedItem?.id,
          })
        );
      } else {
        dispatch(dispatchAction(selectedItem?.id));
      }
    }
    setCreateJdPage((prevValue) => ({
      ...prevValue,
      [fieldId]: selectedItem,
    }));
  };

  const handleCreateJdFormValidation = (): boolean => {
    return (
      validateJobTitle(createJdPage.jobTitleField) &&
      validateCompanyName(createJdPage.companyName) &&
      validateVacancy(createJdPage.vacancy) &&
      validateJobSummary(createJdPage.jobSummary) &&
      validateJobEndDate(createJdPage.job_end_date) &&
      validateJobDescription(createJdPage.jobDescription) &&
      validateExperience(createJdPage.fromExpField, createJdPage.toExpField) &&
      validateSkills(createJdPage.mustHaveSkill) &&
      validateSalaryRange(
        Number(createJdPage.minSalary),
        Number(createJdPage.maxSalary)
      ) &&
      validateCertifiedDegree(createJdPage.certifiedDegree) &&
      validateSpecialization(createJdPage.specialization) &&
      validateCountry(createJdPage.country) &&
      validateContactName(createJdPage.name) &&
      validatePhoneNumber(createJdPage.phoneNo) &&
      validateEmail(createJdPage.email) &&
      validateAboutCompany(createJdPage.aboutCompany) &&
      validateOnlineUrl(createJdPage.onlineUrl) &&
      (createJdPage.job_posting_type === CONTRACT
        ? validateContractFields(
            createJdPage.contractTimeperiod,
            createJdPage.joiningDate
          )
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? validateWalkInDriveFields(
            createJdPage.venue,
            createJdPage.fromWalkDate,
            createJdPage.toWalkDate,
            createJdPage.fromTiming,
            createJdPage.toTiming,
            createJdPage.job_end_date
          )
        : true) &&
      (saveTemplate === true
        ? validateTemplateFields(createJdPage.templateNameField)
        : true)
    );
  };

  const handleSubmit = async () => {
    // --- This method is non functional, neither it validates nor handles the form errors. I couldn't understand it's purpose, so I'm enabling the other/older createJd method---
    const isUpdate = !!createJdPage.id;
    const data = {
      jobTitle: createJdPage.jobTitleField,
      companyName: createJdPage.companyName,
      vacancy: createJdPage.vacancy,
      jobSummary: createJdPage.jobSummary,
      jobDescription: createJdPage.jobDescription,
      fromExpField: createJdPage.fromExpField,
      toExpField: createJdPage.toExpField,
      mustHaveSkill: createJdPage.mustHaveSkill,
      minSalary: createJdPage.minSalary,
      maxSalary: createJdPage.maxSalary,
      certifiedDegree: createJdPage.certifiedDegree,
      specialization: createJdPage.specialization,
      country: createJdPage.country,
      name: createJdPage.name,
      phoneNo: createJdPage.phoneNo,
      email: createJdPage.email,
      aboutCompany: createJdPage.aboutCompany,
      onlineUrl: createJdPage.onlineUrl,
      jobPostingType: createJdPage.job_posting_type,
      contractTimePeriod: createJdPage.contractTimeperiod,
      joiningDate: createJdPage.joiningDate,
      venue: createJdPage.venue,
      fromWalkDate: createJdPage.fromWalkDate,
      toWalkDate: createJdPage.toWalkDate,
      fromTiming: createJdPage.fromTiming,
      toTiming: createJdPage.toTiming,
      templateNameField: createJdPage.templateNameField,
    };

    try {
      const result = await createOrUpdateJD(data, isUpdate);
      console.log("Success:", result);
    } catch (error) {
      console.error("Error while submitting JD:", error);
    }
  };

  const handleDatePickChange = (newDate: Dayjs | null, id: string) => {
    /*
     * handleDatePickChange : Updates the state when a date picker value changes, storing the selected date.
     */
    setCreateJdPage((select) => ({
      ...select,
      [id]: newDate,
    }));
  };

  const onTimePickChange = (newTime: Dayjs | null, id: string) => {
    /*
     * onTimePickChange : Handles changes in time selection, updating the state and validating that the selected
     * times are in the correct order (e.g., from time must be before to time).
     */
    if (!newTime) return;
    setCreateJdPage((select) => ({
      ...select,
      [id]: newTime,
    }));
    setErrors((prev) => {
      return {
        ...prev,
        toTiming: {
          mandatory: false,
          patternFail:
            id === "fromTiming" && createJdPage.toTiming !== null
              ? !newTime.isBefore(createJdPage.toTiming)
              : id === "toTiming" && createJdPage.fromTiming !== null
              ? !createJdPage.fromTiming?.isBefore(newTime)
              : false,
        },
      };
    });
  };

  const mandatoryType = (obj?: unknown, num?: any): boolean => {
    /*
     * mandatoryType : Utility function that checks if a value is defined, not null, and not an empty string,
     * used to validate mandatory fields.
     */
    return obj !== undefined && obj !== null && obj !== "";
  };

  const patternFailType = (
    value: string | undefined | null | any,
    regex: RegExp | any,
    isMandatory?: boolean
  ): boolean => {
    /*
     * patternFailType : Validates a value against a regex pattern, considering whether the field is mandatory;
     * if it is mandatory, it checks for a match; otherwise, it always returns true.
     */
    if (isMandatory) {
      return regex.test(value);
    } else {
      return true;
    }
  };

  const getSelectedIndustry = (industryName: string) => {
    return (
      industryItemsData.find((item) => item.industry_name === industryName) ||
      null
    );
  };

  // Function to update the state with the selected industry
  const updateIndustryState = (industry: IIndustryData | null) => {
    setCreateJdPage((prev) => ({
      ...prev,
      industry,
    }));
  };

  const handleCreateJdFormData = () => {
    /*
     * handleCreateJdFormData : Constructs and returns an object containing the job description data,
     * formatted for submission. It aggregates skill data, formats dates, and handles conditional fields
     * based on the provided job posting type and other criteria.
     */
    const skillsData = createJdPage.mustHaveSkill
      .map((skill) => {
        return {
          skill: skill,
          ismandatory: true,
        };
      })
      .concat(
        createJdPage.goodToHaveSkill.map((skill) => {
          return {
            skill: skill,
            ismandatory: false,
          };
        })
      );

    const FormData = {
      jd_details: {
        posting_type: createJdPage.job_posting_type,
        job_title: createJdPage.jobTitleField,
        job_end_date: createJdPage.job_end_date?.format("YYYY-MM-DD"),
        job_summary: createJdPage.jobSummary,
        job_description: createJdPage.jobDescription,
        industry: createJdPage.industry?.id,
        category: createJdPage.category?.id,
        roles: createJdPage.roles?.id,
        minimum_experience: Number(createJdPage.fromExpField),
        maximum_experience: Number(createJdPage.toExpField),
        certified_degree_name: createJdPage.certifiedDegree?.id,
        specialization: createJdPage.specialization?.id,
        currency:
          createJdPage.minSalary === "" && createJdPage.maxSalary === ""
            ? null
            : createJdPage.currency?.cc,
        minimum_salary:
          createJdPage.minSalary !== "" ? Number(createJdPage.minSalary) : null,
        maximum_salary:
          createJdPage.maxSalary !== "" ? Number(createJdPage.maxSalary) : null,
        contract_joining_date: createJdPage.joiningDate?.format("YYYY-MM-DD"),
        contract_duration: createJdPage.contractTimeperiod,
        contract_billing_rate: createJdPage.billingRate,
        walkin_city_name: createJdPage.venue,
        walkin_start_date: createJdPage.fromWalkDate?.format("YYYY-MM-DD"),
        walkin_end_date: createJdPage.toWalkDate?.format("YYYY-MM-DD"),
        walkin_start_time: createJdPage.fromTiming?.format(
          "HH:mm:ss YYYY-MM-DD"
        ),
        walkin_end_time: createJdPage.toTiming?.format("HH:mm:ss YYYY-MM-DD"),
        vacancy: createJdPage.vacancy,
        notice_period: createJdPage.notice_period,
        country: createJdPage.country ? [createJdPage.country.name] : null,
        state: createJdPage.state ? [createJdPage.state.name] : null,
        city: createJdPage.city ? [createJdPage.city.name] : null,
        company_name:
          createJdPage.companyName === "" ? null : createJdPage.companyName,
        about_company: createJdPage.aboutCompany,
        name: createJdPage.name,
        phone_number: createJdPage.phoneNo,
        email: createJdPage.email,
        apply_online_url: createJdPage.onlineUrl,
        is_short_jd: false,
        template_name:
          createJdPage.templateNameField !== ""
            ? createJdPage.templateNameField
            : null,
        is_template: saveTemplate,
        created_at: new Date().toISOString().split("T")[0],
        status: createJdPage.status,
        client_name:
          createJdPage.client?.clientName !== ""
            ? createJdPage.client?.clientName
            : null,
        job_apply_end_date: null,
      },
      jd_skills_details: skillsData,
    };
    return FormData;
  };

  const handleCreateJdFormErrors = () => {
    /*
     * The function `handleCreateJdFormErrors` validates individual fields of the job description form,
     * setting errors in the state based on the validation.
     */

    if (createJdPage?.jobTitleField === "") {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobTitleField: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    } else if (
      !patternFailType(
        createJdPage?.jobTitleField,
        alphaNumericWithSpaceOnlySpecialCharPattern
      )
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobTitleField: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (createJdPage?.job_end_date === null) {
      setErrors((prev) => {
        return {
          ...prev,
          jobEndDate: {
            mandatory: true,
          },
        };
      });
    }

    if (
      createJdPage?.companyName !== "" &&
      !patternFailType(
        createJdPage?.companyName,
        alphaNumericWithSpaceOnlySpecialCharPattern
      )
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          companyName: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.vacancy !== null &&
      Number(createJdPage.vacancy) > 0 &&
      !patternFailType(
        createJdPage?.vacancy,
        onlyNumbersAboveZeroAndMaxFourDigits
      )
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          vacancy: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.jobSummary !== null &&
      !patternFailType(createJdPage?.jobSummary, alphabetsWithTwoThousandChar)
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobSummary: {
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.jobDescription !== null &&
      !patternFailType(
        createJdPage?.jobDescription,
        alphabetsWithTwoThousandChar
      )
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobDescription: {
            patternFail: true,
          },
        };
      });
    }

    if (createJdPage.fromExpField > createJdPage.toExpField) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          toExpField: {
            mandatory: false,
            patternFail: false,
            greaterThan: true,
          },
        };
      });
    }

    if (createJdPage?.mustHaveSkill.length === 0) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          mustHaveSkill: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    } else {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          mustHaveSkill: {
            mandatory: false,
            patternFail: false,
          },
        };
      });
    }

    if (createJdPage?.certifiedDegree === null) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          certifiedDegree: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    }

    if (
      createJdPage.currency === null &&
      (createJdPage?.minSalary !== "" || createJdPage?.maxSalary !== "")
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          currency: {
            mandatory: true,
          },
        };
      });
    }

    if (createJdPage?.specialization === null) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          specialization: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    }

    if (
      createJdPage.job_posting_type === CONTRACT &&
      createJdPage?.joiningDate === null
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          joiningDate: {
            mandatory: true,
          },
        };
      });
    }

    if (
      createJdPage.job_posting_type === CONTRACT &&
      createJdPage?.contractTimeperiod === ""
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          contractTimeperiod: {
            mandatory: true,
            patternFail: true,
          },
        };
      });
    } else if (
      createJdPage.job_posting_type === CONTRACT &&
      createJdPage?.contractTimeperiod !== "" &&
      !patternFailType(
        createJdPage?.contractTimeperiod,
        alphanumericWithSpaceAndDotChar
      )
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          contractTimeperiod: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      (createJdPage.job_posting_type === WALK_IN_DRIVE &&
        createJdPage?.venue === "") ||
      createJdPage?.venue === null
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          venue: {
            mandatory: true,
            patternFail: true,
          },
        };
      });
    } else if (
      !patternFailType(createJdPage?.venue, alphabetsWithTwoThousandChar)
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          venue: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.job_posting_type === WALK_IN_DRIVE &&
      createJdPage.fromWalkDate === null
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          fromWalkDate: {
            mandatory: true,
          },
        };
      });
    }

    if (
      createJdPage.job_posting_type === WALK_IN_DRIVE &&
      createJdPage.toWalkDate === null
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          toWalkDate: {
            mandatory: true,
          },
        };
      });
    }

    if (
      createJdPage.job_posting_type === WALK_IN_DRIVE &&
      createJdPage?.fromTiming === null
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          fromTiming: {
            mandatory: true,
          },
        };
      });
    }

    if (
      createJdPage.job_posting_type === WALK_IN_DRIVE &&
      createJdPage?.toTiming === null
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          toTiming: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    }
    if (
      createJdPage.job_posting_type === WALK_IN_DRIVE &&
      createJdPage.fromTiming !== null &&
      createJdPage.toTiming !== null &&
      !createJdPage.fromTiming?.isBefore(createJdPage.toTiming)
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          toTiming: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }
    if (createJdPage?.country === null) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          country: {
            mandatory: true,
          },
        };
      });
    }

    if (createJdPage?.name === "") {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          name: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    } else if (
      !patternFailType(createJdPage?.name, onlyAlphabetsWithMaxFifteenChar)
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          name: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (createJdPage?.email === "") {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          email: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    } else if (!patternFailType(createJdPage?.email, validEmailPattern)) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          email: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.phoneNo !== null &&
      !patternFailType(createJdPage?.phoneNo, mobileNumberPattern)
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          phoneNo: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.aboutCompany !== null &&
      !patternFailType(createJdPage?.aboutCompany, alphabetsWithTwoThousandChar)
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobDescription: {
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.onlineUrl !== null &&
      !patternFailType(createJdPage?.onlineUrl, onlineUrl)
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          vacancy: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (saveTemplate) {
      if (createJdPage?.templateNameField === "") {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            templateNameField: {
              mandatory: true,
              patternFail: false,
            },
          };
        });
      } else if (
        !patternFailType(createJdPage?.templateNameField, alphanumericSnakeCase)
      ) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            templateNameField: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }
    }

    setToaster((toast) => {
      return {
        ...toast,
        open: true,
        message: t("CREATE_JD.PLEASE_FILL_THE_FIELDS"),
        status: Status.Error,
      };
    });
  };

  const handleCreateJd = async () => {
    /*
     * The function `handleCreateJd` handles the creation of a job description:
     * Validates the form data using `handleCreateJdFormValidation`.
     * If validation passes, it prepares the form data with `handleCreateJdFormData` and sends it via `createJdThunk`.
     * If the job description creation is successful, it resets the form, updates the toaster notification to indicate success, and hides the template option.
     * If validation fails, it triggers `handleCreateJdFormErrors` to set errors and updates the toaster notification to indicate failure.
     */

    if (handleCreateJdFormValidation()) {
      const FormData = handleCreateJdFormData();
      const result = await createJdThunk(FormData);
      if (result.success) {
        setSaveTemplate(false);
        setCreateJdPage(initialCreateJdState);
        setToaster((toast) => ({
          ...toast,
          open: true,
          message: t("CREATE_JD.JOB_CREATED_SUCCESSFULLY"),
          status: Status.Success,
        }));
      }
    } else {
      console.log("validation error");
      handleCreateJdFormErrors();
      setToaster((toast) => ({
        ...toast,
        open: true,
        message: t("CREATE_JD.FAILED_TO_CREATE_A_JD"),
        status: Status.Error,
      }));
    }
  };
  // const handleCreateOrUpdateJd = () => {
  //   console.log(handleCreateJdFormValidation(),  "handleCreateJd");
  // if (handleCreateJdFormData()){
  //   const FormData = handleCreateJdFormData()
  //   if (jobDetails){
  //     dispatch(UpdateJdThunk(FormData))
  //   } else{
  //     dispatch(createJdThunk(FormData))
  //   }
  //   setSaveTemplate(false)
  //   setCreateJdPage(initialCreateJdState)
  // }else{
  //   handleCreateJdFormErrors()
  // }

  //   }
  // };

  const handleCancelCreateJd = () => {
    /*
     * The function `handleCancelCreateJd` resets the job description form and error states,
     * and navigates back to the jobs page.
     */
    setCreateJdPage(initialCreateJdState);
    setErrors(initialErrorJdState);
    navigate(jobsPage);
  };

  const getSkillsValue = (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    /*
     * The function `getSkillsValue` handles changes to the 'must have' skills field:
     * Validates that all selected skills match a specified pattern.
     * If valid, updates the form state for 'must have' skills and clears any errors related to this field.
     * If invalid, sets an error indicating a pattern failure for the 'must have' skills.
     */
    const allMatch = value.every((skill) =>
      alphabetsNumbersWithDotAndHashAndSpace.test(skill)
    );

    if (allMatch) {
      setCreateJdPage((prev) => {
        return {
          ...prev,
          mustHaveSkill: value,
        };
      });
      setErrors((prev) => {
        return {
          ...prev,
          mustHaveSkill: {
            mandatory: false,
            patternFail: false,
          },
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          mustHaveSkill: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }
  };

  const getGoodToHaveSkillsValue = (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    /*
     * The function `getGoodToHaveSkillsValue` updates the form state for 'good to have' skills:
     * Sets the 'good to have' skills in the form state whenever a new value is selected.
     */
    setCreateJdPage((prev) => {
      return {
        ...prev,
        goodToHaveSkill: value,
      };
    });
  };

  const handleToastClose = () => {
    /*
     * The function `handleToastClose` closes the toaster notification:
     * Updates the toaster state to mark it as closed and clears the message.
     */
    setToaster((toast) => {
      return {
        ...toast,
        open: false,
        message: "",
      };
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackBar {...toaster} handleClose={handleToastClose} />
      <Box>
        <Paper sx={{ py: 4 }}>
          <Grid container px={3.5} gap={3}>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <DropDownitems
                placeholder={t("CREATE_JD.JOB_POSTING_TYPE")}
                id="job_posting_type"
                value={createJdPage.job_posting_type}
                label={t("CREATE_JD.JOB_POSTING_TYPE")}
                menuItems={MenuItems}
                isMandatory={true}
                handleChange={handleFieldChange}
              />
              <Typography
                fontSize={"var(--font-size-sm)"}
                color={"var(--color-text-grey)"}
                p={1.5}
              >
                <span style={{ color: "var(--color-dark-red)" }}>
                  {t("CREATE_JD.NOTE")}:
                </span>{" "}
                {t("CREATE_JD.PLEASE_SELECT_A_JOB_TYPE")}
              </Typography>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <DropDownitems
                placeholder={t("CREATE_JD.DOMAIN")}
                id="domain"
                value={createJdPage.domain}
                label={t("CREATE_JD.DOMAIN")}
                menuItems={RegionItems}
                handleChange={handleFieldChange}
                isDisabled
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.CLIENT_NAME")}</InputLabel>
                <Select
                  id="clientName"
                  data-testid="clientName"
                  value={createJdPage.client?.clientName ?? ""}
                  label={t("CREATE_JD.CLIENT_NAME")}
                  onChange={(e) => handleClientChange(e.target.value)}
                >
                  {client?.map((menu: IClientDetail) => (
                    <MenuItem key={menu.id} value={menu.clientName}>
                      {menu.clientName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container px={3.5} mt={2} gap={3}>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoTextFiled
                label={t("CREATE_JD.JOB_TITLE")}
                required
                id="jobTitleField"
                errorMessage={
                  errors?.jobTitleField?.mandatory ||
                  errors?.jobTitleField?.patternFail
                }
                helperText={
                  errors?.jobTitleField?.mandatory
                    ? t("CREATE_JD.PLEASE_ENTER_JOB_TITLE")
                    : errors?.jobTitleField?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                value={createJdPage.jobTitleField ?? ""}
                handleChange={(e) => {
                  handleFieldChange(
                    e,
                    "jobTitleField",
                    true,
                    alphaNumericWithSpaceOnlySpecialCharPattern
                  );
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoTextFiled
                label={t("CREATE_JD.COMPANY_NAME")}
                id="companyName"
                errorMessage={errors?.companyName?.patternFail}
                helperText={
                  errors?.companyName?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                value={createJdPage.companyName ?? ""}
                handleChange={(e) => {
                  handleFieldChange(
                    e,
                    "companyName",
                    false,
                    alphaNumericWithSpaceOnlySpecialCharPattern
                  );
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <DropDownitems
                placeholder={t("CREATE_JD.STATUS")}
                id="status"
                value={createJdPage.status}
                label={t("CREATE_JD.STATUS")}
                menuItems={StatusItems}
                handleChange={handleFieldChange}
                isDisabled={!jobDetails}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.INDUSTRY")}</InputLabel>
                <Select
                  id="industry"
                  value={
                    createJdPage.industry
                      ? createJdPage.industry.industry_name
                      : ""
                  }
                  label={t("CREATE_JD.INDUSTRY")}
                  onChange={(e) => handleIndustryChange(e.target.value)}
                >
                  {industryItemsData.map((menu: IIndustryData) => (
                    <MenuItem key={menu.id} value={menu.industry_name}>
                      {menu.industry_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.CATEGORY")}</InputLabel>
                <Select
                  id="category"
                  value={createJdPage.category?.category_name ?? ""}
                  label={t("CREATE_JD.CATEGORY")}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                >
                  {categoryItemsData.map((menu: ICategoryData) => (
                    <MenuItem key={menu.id} value={menu.category_name}>
                      {menu.category_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.ROLES")}</InputLabel>
                <Select
                  id="roles"
                  value={createJdPage.roles?.role_name ?? ""}
                  label={t("CREATE_JD.ROLES")}
                  onChange={(e) => handleRolesChange(e.target.value)}
                  disabled={!createJdPage.category}
                >
                  {rolesItemsData.map((menu: IRolesData) => (
                    <MenuItem key={menu.id} value={menu.role_name}>
                      {menu.role_name}
                    </MenuItem>
                  ))}
                </Select>
                {!createJdPage.category && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_FIRST_CATEGORY")}*
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoDatePicker
                required
                value={createJdPage.job_end_date ?? null}
                label={t("CREATE_JD.JOB_END_DATE")}
                id="job_end_date"
                error={
                  createJdPage?.job_end_date === null &&
                  errors?.jobEndDate?.mandatory
                }
                helperText={
                  createJdPage.job_end_date === null &&
                  errors?.jobEndDate?.mandatory
                    ? t("CREATE_JD.PLEASE_SELECT_JOB_END_DATE")
                    : ""
                }
                handleDatePickChange={handleDatePickChange}
                minDate={dayjs().add(1, "day")}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} pt={1}>
              <AssistoTextFiled
                label={t("CREATE_JD.VACANCY")}
                id="vacancy"
                value={String(createJdPage.vacancy ?? "")}
                errorMessage={errors?.vacancy?.patternFail}
                helperText={
                  errors?.vacancy?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                handleChange={(e) => {
                  handleFieldChange(
                    e,
                    "vacancy",
                    false,
                    onlyNumbersAboveZeroAndMaxFourDigits
                  );
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} pt={1}>
              <DropDownitems
                placeholder={t("CREATE_JD.NOTICE_PERIOD")}
                id="notice_period"
                value={createJdPage.notice_period}
                label={t("CREATE_JD.NOTICE_PERIOD")}
                menuItems={NoticePeriodItems}
                handleChange={handleFieldChange}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <BusinessCenterRoundedIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.WORK_EXPERIENCE")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={7.2} lg={7.2} md={12} sm={12}>
              <FormControl fullWidth error={errors?.jobSummary?.patternFail}>
                <TextEditor
                  onChange={(content) => {
                    handleTextEditor(
                      content,
                      "jobSummary",
                      false,
                      alphabetsWithTwoThousandChar
                    );
                  }}
                  placeholder={t("CREATE_JD.JOB_SUMMARY")}
                  data-testid="jobSummary"
                  id="jobSummary"
                  value={createJdPage.jobSummary ?? ""}
                  style={{ height: "80px", marginBottom: "50px" }}
                />
                {createJdPage.jobSummary !== "" &&
                  errors?.jobSummary?.patternFail && (
                    <FormHelperText>
                      {t(
                        "CREATE_JD.PLEASE_ENTER_MINIMUM_TWO_THOUSAND_CHARACTER"
                      )}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid
              item
              xl={3.5}
              lg={3.5}
              md={12}
              sm={12}
              display={"grid"}
              alignItems={"center"}
            >
              <Grid item xs={12} xl={12} sx={{ margin: "6px 0" }}>
                <Grid container alignItems={"center"} gap={2}>
                  <Grid item xs={3} pl={1}>
                    <Typography>
                      {t("CREATE_JD.EXPERIENCE")}
                      <span style={{ marginLeft: "3px", color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={3.5}>
                    <AssistoTextFiled
                      required
                      errorMessage={
                        errors?.fromExpField?.mandatory ||
                        errors?.fromExpField?.patternFail ||
                        errors?.fromExpField?.lessThan
                      }
                      helperText={
                        errors?.fromExpField?.mandatory
                          ? t("CREATE_JD.PLEASE_ENTER_EXPERIENCE")
                          : errors?.fromExpField?.patternFail
                          ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                          : ""
                      }
                      id="fromExpField"
                      label={t("CREATE_JD.FROM")}
                      value={String(createJdPage.fromExpField)}
                      handleChange={(e) => {
                        handleFieldChange(
                          e,
                          "fromExpField",
                          true,
                          experienceFieldWithDecimalChar
                        );
                      }}
                    />
                  </Grid>
                  <Grid item lg={0.25}>
                    <hr />
                  </Grid>
                  <Grid item xs={3.5}>
                    <AssistoTextFiled
                      errorMessage={
                        errors?.toExpField?.mandatory ||
                        errors?.toExpField?.patternFail ||
                        errors?.toExpField?.greaterThan
                      }
                      required
                      helperText={
                        errors?.toExpField?.mandatory
                          ? t("CREATE_JD.PLEASE_ENTER_EXPERIENCE")
                          : errors?.toExpField?.patternFail
                          ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                          : errors.toExpField.greaterThan
                          ? t("CREATE_JD.TO_VALUE_SHOULD_BE_GREATER")
                          : ""
                      }
                      id="toExpField"
                      label={t("CREATE_JD.TO")}
                      value={String(createJdPage.toExpField)}
                      handleChange={(e) => {
                        handleFieldChange(
                          e,
                          "toExpField",
                          true,
                          experienceFieldWithDecimalChar
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={10.83} lg={10.83} md={12} sm={12}>
              <FormControl
                fullWidth
                error={errors?.jobDescription?.patternFail}
              >
                <TextEditor
                  onChange={(content) => {
                    handleTextEditor(
                      content,
                      "jobDescription",
                      false,
                      alphabetsWithFiveThousandChar
                    );
                  }}
                  placeholder={t("CREATE_JD.JOB_DESCRIPTION")}
                  id="jobDescription"
                  data-testid="jobDescription"
                  value={createJdPage.jobDescription ?? ""}
                  style={{ height: "80px", marginBottom: "50px" }}
                />
                {createJdPage.jobDescription !== "" &&
                  errors?.jobDescription?.patternFail && (
                    <FormHelperText>
                      {t(
                        "CREATE_JD.PLEASE_ENTER_MINIMUM_FIVE_THOUSAND_CHARACTER"
                      )}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <AssistantIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.SKILLS_TITLE")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={7.25} lg={7.25} md={12} sm={12}>
              <AssistoCustomAddInputField
                required
                label={t("CREATE_JD.MUST_HAVE_SKILL")}
                placeholder={t("CREATE_JD.PLEASE_ENTER_SKILL")}
                value={createJdPage.mustHaveSkill}
                skillsList={skillsItemData}
                onChange={getSkillsValue}
                error={
                  errors?.mustHaveSkill?.mandatory ||
                  errors?.mustHaveSkill?.patternFail
                }
                helperText={
                  errors?.mustHaveSkill?.mandatory
                    ? t("CREATE_JD.PLEASE_ENTER_ATLEAST_ONE_MUST_HAVE_SKILL")
                    : errors.mustHaveSkill.patternFail
                    ? t("CREATE_JD.PLEASE_REMOVE_INVALID_DATA")
                    : ""
                }
              />
            </Grid>
            <Grid item xl={7.25} lg={7.25} md={12} sm={12}>
              <AssistoCustomAddInputField
                required={false}
                value={createJdPage.goodToHaveSkill}
                label={t("CREATE_JD.GOOD_TO_HAVE_SKILL")}
                placeholder={t("CREATE_JD.PLEASE_ENTER_SKILL")}
                skillsList={selectedSkillData}
                onChange={getGoodToHaveSkillsValue}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <SchoolIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.EDUCATION")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={6} sm={12}>
              <FormControl fullWidth error={errors?.certifiedDegree?.mandatory}>
                <InputLabel>
                  {t("CREATE_JD.CERTIFIED_DEGREE_NAME")}
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color={"var(--color-dark-red)"}
                    >
                      &nbsp;*
                    </Typography>
                  </React.Fragment>
                </InputLabel>
                <Select
                  id="certifiedDegree"
                  value={
                    createJdPage.certifiedDegree?.education_degree_name ?? ""
                  }
                  label={t("CREATE_JD.CERTIFIED_DEGREE_NAME")}
                  onChange={(e) => handleCertifiedDegreeChange(e.target.value)}
                  required
                >
                  {degreeItemsData.map((menu: IEducationData) => (
                    <MenuItem key={menu.id} value={menu.education_degree_name}>
                      {menu.education_degree_name}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.certifiedDegree?.mandatory && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_CERTIFIED_DEGREE_NAME")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={6} sm={12}>
              <FormControl fullWidth error={errors?.specialization?.mandatory}>
                <InputLabel>
                  {t("CREATE_JD.SPECIALIZATION")}
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color={"var(--color-dark-red)"}
                    >
                      &nbsp;*
                    </Typography>
                  </React.Fragment>
                </InputLabel>
                <Select
                  id="specialization"
                  value={createJdPage.specialization?.specialization_name ?? ""}
                  label={t("CREATE_JD.SPECIALIZATION")}
                  onChange={(e) => handleSpecializationChange(e.target.value)}
                  required
                  disabled={!createJdPage.certifiedDegree}
                >
                  {specializationItemsData.map((menu: ISpecializationData) => (
                    <MenuItem key={menu.id} value={menu.specialization_name}>
                      {menu.specialization_name}
                    </MenuItem>
                  ))}
                </Select>
                {!createJdPage.certifiedDegree && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_FIRST_CERTIFIED_DEGREE")}*
                  </FormHelperText>
                )}
                {errors?.specialization.mandatory &&
                createJdPage.certifiedDegree !== null &&
                createJdPage.specialization === null ? (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_SPECIALIZATION")}*
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <PaidIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.SALARY")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={6} sm={12}>
              <AssistoTextFiled
                errorMessage={errors?.minSalary?.patternFail}
                required={false}
                helperText={
                  errors?.minSalary?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                id="minSalary"
                label={t("CREATE_JD.MINIMUM_SALARY")}
                value={String(
                  createJdPage.minSalary !== null ? createJdPage.minSalary : ""
                )}
                handleChange={(e) => {
                  handleFieldChange(e, "minSalary", false, digitsOnly);
                }}
              />
            </Grid>
            <Grid item xs={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoTextFiled
                errorMessage={
                  errors?.maxSalary?.patternFail || errors?.maxSalary?.maxSalary
                }
                required={false}
                helperText={
                  errors?.maxSalary?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : errors?.maxSalary.maxSalary
                    ? t("CREATE_JD.MAX_SALARY_SHOULD_BE_GREATER")
                    : ""
                }
                id="maxSalary"
                label={t("CREATE_JD.MAXIMUM_SALARY")}
                value={String(
                  createJdPage.maxSalary !== null ? createJdPage.maxSalary : ""
                )}
                handleChange={(e) => {
                  handleFieldChange(e, "maxSalary", false, digitsOnly);
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth error={errors?.currency?.mandatory}>
                <InputLabel>{t("CREATE_JD.CURRENCY")}</InputLabel>
                <Select
                  id="currency"
                  value={createJdPage.currency?.cc ?? ""}
                  label={t("CREATE_JD.CURRENCY")}
                  onChange={(e) => handleCurrencyChange(e.target.value)}
                  inputProps={{
                    "data-testid": "currency",
                  }}
                  disabled={
                    createJdPage.minSalary === "" &&
                    createJdPage.maxSalary === ""
                  }
                >
                  {currencyItemData.map((menu: ICurrencyResponse) => (
                    <MenuItem key={menu.cc} value={menu.cc}>
                      {menu.cc}
                    </MenuItem>
                  ))}
                </Select>
                {(!createJdPage?.minSalary ||
                  createJdPage?.minSalary === "") && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_MINIMUM_SALARY")}*
                  </FormHelperText>
                )}
                {errors?.currency?.mandatory ? (
                  <FormHelperText>
                    {t("CREATE_JD.CURRENCY_IS_MANDATORY")}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <FmdGoodIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.JOB_LOCATION")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl
                fullWidth
                error={
                  (createJdPage.country === null ||
                    createJdPage.country?.name === "") &&
                  errors?.country?.mandatory
                }
              >
                <InputLabel>
                  {t("CREATE_JD.COUNTRY")}
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color={"var(--color-dark-red)"}
                    >
                      &nbsp;*
                    </Typography>
                  </React.Fragment>
                </InputLabel>
                <Select
                  id="country"
                  value={createJdPage.country?.name ?? ""}
                  label={t("CREATE_JD.COUNTRY")}
                  onChange={(e) => handleCountryChange(e.target.value)}
                  inputProps={{
                    "data-testid": "country",
                  }}
                  required
                >
                  {countryItems.map((menu: ILocationInfoResponse) => (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
                {(createJdPage.country === null ||
                  createJdPage.country?.name === "") &&
                  errors?.country?.mandatory && (
                    <FormHelperText>
                      {t("CREATE_JD.PLEASE_SELECT_FIRST_COUNTRY")}*
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.STATE")}</InputLabel>
                <Select
                  id="state"
                  value={createJdPage.state?.name ?? ""}
                  label={t("CREATE_JD.STATE")}
                  onChange={(e) => handleStateChange(e.target.value)}
                  disabled={!createJdPage.country}
                >
                  {stateItems.map((menu: ILocationInfoResponse) => (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
                {!createJdPage.country && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_FIRST_COUNTRY")}*
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.CITY")}</InputLabel>
                <Select
                  id="city"
                  value={createJdPage.city?.name ?? ""}
                  label={t("CREATE_JD.CITY")}
                  onChange={(e) => handleCityChange(e.target.value)}
                  disabled={!createJdPage.country || !createJdPage.state}
                >
                  {cityItems.map((menu: ILocationInfoResponse) => (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
                {!createJdPage.country && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_COUNTRY_&_STATE")}*
                  </FormHelperText>
                )}
                {createJdPage.country && !createJdPage.state && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_STATE")}*
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {createJdPage.job_posting_type === CONTRACT && (
              <>
                <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
                  <Stack direction={"row"} gap={1}>
                    <DrawRoundedIcon />
                    <Typography
                      fontSize={"var(--font-size-18)"}
                      fontWeight={"var(--font-weight-500)"}
                    >
                      {t("CREATE_JD.CONTRACT")}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                  <AssistoDatePicker
                    required
                    id="joiningDate"
                    value={createJdPage.joiningDate ?? null}
                    label={t("CREATE_JD.EARLY_JOINING_DATE")}
                    handleDatePickChange={handleDatePickChange}
                    minDate={dayjs().add(1, "day")}
                    error={
                      createJdPage?.joiningDate === null &&
                      errors?.joiningDate?.mandatory
                    }
                    helperText={
                      createJdPage?.joiningDate === null &&
                      errors?.joiningDate?.mandatory
                        ? t("CREATE_JD.EARLY_JOINING_DATE_MANDATORY")
                        : ""
                    }
                  />
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} pt={1}>
                  <AssistoTextFiled
                    errorMessage={
                      errors?.contractTimeperiod?.mandatory ||
                      errors?.contractTimeperiod?.patternFail
                    }
                    required
                    helperText={
                      errors?.contractTimeperiod?.mandatory
                        ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                        : errors?.contractTimeperiod?.patternFail
                        ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                        : ""
                    }
                    id="contractTimeperiod"
                    label={t("CREATE_JD.CONTRACT_TIME_PERIOD")}
                    value={String(
                      createJdPage.contractTimeperiod !== null
                        ? createJdPage.contractTimeperiod
                        : ""
                    )}
                    handleChange={(e) => {
                      handleFieldChange(
                        e,
                        "contractTimeperiod",
                        true,
                        alphanumericWithSpaceAndDotChar
                      );
                    }}
                  />
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} pt={1}>
                  <DropDownitems
                    placeholder={t("CREATE_JD.BILLING_RATE")}
                    id="billingRate"
                    value={createJdPage.billingRate ?? ""}
                    label={t("CREATE_JD.BILLING_RATE")}
                    menuItems={BillingRateItems}
                    isMandatory={true}
                    handleChange={handleFieldChange}
                  />
                </Grid>
              </>
            )}
            {createJdPage.job_posting_type === WALK_IN_DRIVE && (
              <>
                <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
                  <Stack direction={"row"} gap={1}>
                    <SaveAsIcon />
                    <Typography
                      fontSize={"var(--font-size-18)"}
                      fontWeight={"var(--font-weight-500)"}
                    >
                      {t("CREATE_JD.WALK_IN_DETAILS")}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xl={7.2} lg={7.2} md={7.2} sm={12}>
                  <FormControl
                    fullWidth
                    error={
                      (createJdPage?.venue === "" ||
                        createJdPage.venue === null) &&
                      errors?.venue?.mandatory &&
                      errors?.venue?.patternFail
                    }
                  >
                    <TextField
                      fullWidth
                      label={
                        <React.Fragment>
                          {t("CREATE_JD.VENUE")}
                          <Typography
                            component="span"
                            variant="body2"
                            style={{ color: "var(--color-dark-red)" }}
                          >
                            &nbsp;*
                          </Typography>
                        </React.Fragment>
                      }
                      multiline
                      id="venue"
                      value={createJdPage.venue ?? ""}
                      rows={3}
                      inputProps={{
                        "data-testid": "venue",
                      }}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        handleFieldChange(
                          e,
                          "venue",
                          true,
                          alphabetsWithTwoThousandChar
                        )
                      }
                      error={
                        (createJdPage?.venue === "" ||
                          createJdPage.venue === null) &&
                        errors?.venue?.mandatory &&
                        errors?.venue?.patternFail
                      }
                    />
                    {errors?.venue?.mandatory === null && (
                      <FormHelperText error>
                        {t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")}
                      </FormHelperText>
                    )}
                    {createJdPage.venue !== "" &&
                      errors?.venue?.patternFail && (
                        <FormHelperText>
                          {t(
                            "CREATE_JD.PLEASE_ENTER_MINIMUM_TWO_THOUSAND_CHARACTER"
                          )}
                        </FormHelperText>
                      )}
                    {createJdPage.venue === "" && errors?.venue?.mandatory && (
                      <FormHelperText>
                        {t(
                          "CREATE_JD.PLEASE_ENTER_MINIMUM_TWO_THOUSAND_CHARACTER"
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                  <AssistoDatePicker
                    required
                    value={createJdPage.fromWalkDate ?? null}
                    label={t("CREATE_JD.WALK_IN_DATE_FROM")}
                    id="fromWalkDate"
                    handleDatePickChange={handleDatePickChange}
                    minDate={dayjs().add(1, "day")}
                    error={
                      createJdPage?.fromWalkDate === null &&
                      errors?.fromWalkDate?.mandatory
                    }
                    helperText={
                      createJdPage?.fromWalkDate === null &&
                      errors?.fromWalkDate?.mandatory
                        ? t("CREATE_JD.WALK_IN_DATE_IS_MANDATORY")
                        : ""
                    }
                  />
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                  <AssistoDatePicker
                    required
                    value={createJdPage.toWalkDate ?? null}
                    id="toWalkDate"
                    label={t("CREATE_JD.WALK_IN_DATE_TO")}
                    handleDatePickChange={handleDatePickChange}
                    minDate={dayjs().add(2, "day")}
                    error={
                      createJdPage?.toWalkDate === null &&
                      errors?.toWalkDate?.mandatory
                    }
                    helperText={
                      createJdPage?.toWalkDate === null &&
                      errors?.fromWalkDate?.mandatory
                        ? t("CREATE_JD.WALK_IN_DATE_IS_MANDATORY")
                        : ""
                    }
                  />
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                  <AssistoTimePicker
                    required
                    label={t("CREATE_JD.FROM_TIMING")}
                    onTimePickChange={onTimePickChange}
                    id="fromTiming"
                    value={createJdPage.fromTiming ?? null}
                    error={
                      createJdPage.fromTiming === null &&
                      errors?.fromTiming?.mandatory
                    }
                    helperText={
                      createJdPage?.fromTiming === null &&
                      errors?.fromTiming?.mandatory
                        ? t("CREATE_JD.FROM_TIMING_IS_MANDATORY")
                        : ""
                    }
                  />
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                  <AssistoTimePicker
                    required
                    label={t("CREATE_JD.TO_TIMING")}
                    id="toTiming"
                    onTimePickChange={onTimePickChange}
                    value={createJdPage.toTiming ?? null}
                    error={
                      errors?.toTiming?.mandatory ||
                      errors?.toTiming?.patternFail
                    }
                    helperText={
                      errors?.toTiming?.mandatory
                        ? t("CREATE_JD.TO_TIMING_IS_MANDATORY")
                        : errors?.toTiming?.patternFail
                        ? t("CREATE_JD.TO_TIMING_SHOULD_BE_GREATER")
                        : ""
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <ContactsIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.CONTACT")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoTextFiled
                label={t("CREATE_JD.NAME")}
                required
                id="name"
                value={createJdPage.name ?? ""}
                errorMessage={
                  errors?.name?.mandatory || errors?.name?.patternFail
                }
                helperText={
                  errors?.name?.mandatory
                    ? t("CREATE_JD.PLEASE_ENTER_NAME")
                    : errors?.name?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                handleChange={(e) => {
                  handleFieldChange(
                    e,
                    "name",
                    true,
                    onlyAlphabetsWithMaxFifteenChar
                  );
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoTextFiled
                errorMessage={errors?.phoneNo?.patternFail}
                required={false}
                helperText={
                  errors?.phoneNo?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                id="phoneNo"
                label={t("CREATE_JD.PHONE_NUMBER")}
                value={String(
                  createJdPage.phoneNo !== null ? createJdPage.phoneNo : ""
                )}
                handleChange={(e) => {
                  handleFieldChange(e, "phoneNo", false, mobileNumberPattern);
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoTextFiled
                label={t("CREATE_JD.EMAIL")}
                required
                id="email"
                value={createJdPage.email ?? ""}
                errorMessage={
                  errors?.email?.mandatory || errors?.email?.patternFail
                }
                helperText={
                  errors?.email?.mandatory
                    ? t("CREATE_JD.PLEASE_ENTER_EMAIL")
                    : errors?.email?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                handleChange={(e) => {
                  handleFieldChange(e, "email", true, validEmailPattern);
                }}
              />
            </Grid>
            <Grid item xl={7.2} lg={7.2} md={7.2} sm={12}>
              <FormControl fullWidth error={errors?.aboutCompany?.patternFail}>
                <TextField
                  fullWidth
                  label={t("CREATE_JD.ABOUT_COMPANY")}
                  multiline
                  id="aboutCompany"
                  value={createJdPage.aboutCompany ?? ""}
                  rows={3}
                  inputProps={{
                    "data-testid": "aboutCompany",
                  }}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    handleFieldChange(
                      e,
                      "aboutCompany",
                      false,
                      alphabetsWithTwoThousandChar
                    )
                  }
                />
                {createJdPage.aboutCompany !== "" &&
                  errors?.aboutCompany?.patternFail && (
                    <FormHelperText>
                      {t(
                        "CREATE_JD.PLEASE_ENTER_MINIMUM_TWO_THOUSAND_CHARACTER"
                      )}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid
              item
              xl={3.5}
              lg={3.5}
              md={6}
              sm={12}
              alignItems={"center"}
              display={"grid"}
            >
              <AssistoTextFiled
                errorMessage={errors?.onlineUrl?.patternFail}
                required={false}
                helperText={
                  errors?.onlineUrl?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                id="onlineUrl"
                label={t("CREATE_JD.APPLY_ONLINE_URL")}
                value={String(
                  createJdPage.onlineUrl !== null ? createJdPage.onlineUrl : ""
                )}
                handleChange={(e) => {
                  handleFieldChange(e, "onlineUrl", true, onlineUrl);
                }}
              />
            </Grid>
            <Stack direction={"row"} gap={2} mt={2}>
              <AssistoButton
                buttonVarient={ButtonVarient.Contained}
                name="Save Template"
                handleBtnClick={() => {
                  setSaveTemplate(true);
                }}
              />
              <AssistoButton
                buttonVarient={ButtonVarient.Contained}
                name="Save"
                handleBtnClick={handleCreateJd}
              />
              <AssistoButton
                buttonVarient={ButtonVarient.Outlined}
                name="Cancel"
                handleBtnClick={handleCancelCreateJd}
              />
            </Stack>

            <AssistoModal
              open={saveTemplate}
              handleClose={() => {
                setSaveTemplate(false);
              }}
              isCloseButtonNeeded={true}
              title={t("CREATE_JD.DO_YOU_WANT_TO_SAVE_TEMPLATE?")}
              style={saveTemplateModalStyle}
            >
              <Grid
                marginY={"75px"}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <AssistoTextFiled
                    label={t("CREATE_JD.SELECT_TEMPLATE")}
                    required
                    id="templateNameField"
                    errorMessage={
                      errors?.templateNameField?.mandatory ||
                      errors?.templateNameField?.patternFail
                    }
                    helperText={
                      errors?.templateNameField?.mandatory
                        ? t("CREATE_JD.PLEASE_ENTER_TEMPLATE_NAME")
                        : errors?.templateNameField?.patternFail
                        ? t("CREATE_JD.SNAKE_CASE_NAMING_CONVENTION_ONLY")
                        : ""
                    }
                    value={
                      createJdPage.templateNameField
                        .replace(/\s+/g, "_")
                        .toLowerCase() ?? ""
                    }
                    handleChange={(e) => {
                      handleFieldChange(
                        e,
                        "templateNameField",
                        true,
                        alphanumericSnakeCase
                      );
                    }}
                    isDisabled={!saveTemplate}
                  />
                  <Typography
                    fontSize={"var(--font-size-sm)"}
                    color={"var(--color-text-grey)"}
                    p={1.5}
                  >
                    <span style={{ color: "var(--color-dark-red)" }}>
                      {t("CREATE_JD.NOTE")}:
                    </span>{" "}
                    {t("CREATE_JD.TEMPLATES_AUTO_FILL_ALL_DATA")}
                  </Typography>
                </Grid>
              </Grid>
              <Stack justifyContent={"center"} direction={"row"} gap={2}>
                <AssistoButton
                  buttonVarient={ButtonVarient.Contained}
                  name="Save"
                  handleBtnClick={handleCreateJd}
                />
                <AssistoButton
                  buttonVarient={ButtonVarient.Outlined}
                  name="Cancel"
                  handleBtnClick={() => {
                    setSaveTemplate(false);
                  }}
                />
              </Stack>
            </AssistoModal>
          </Grid>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
export default JobDescription;
