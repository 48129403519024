import { Stack, Typography } from "@mui/material";
import ErrorIcon from "../../assets/images/unauthorized-icon.svg";
import { AssistoButton } from "../AssitoButton";
import ReplyIcon from "@mui/icons-material/Reply";
import { ThemeProvider } from "@emotion/react";
import theme from "../../widgets/Theme/theme";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";
import { assisto_qa_page } from "../../utils/constants";
import { ButtonVarient } from "../../utils/commonEnums";

function ErrorScreen() {
  const { t } = useTranslation(NAMESPACE.COMMON);

  return (
      <ThemeProvider theme={theme}>
        <Stack className="error-screen">
          <img
            className="error-screen__error-img"
            src={ErrorIcon}
            alt="error-icon"
          />
          <Typography variant="h1" textAlign={"center"} mb={1} mt={1}>
            {t("COMMON.ERROR_MESSAGE")}
          </Typography>
          <Typography textAlign={"center"} mb={2}>
            {t("COMMON.ERROR_SIGNIN_MESSAGE")}
          </Typography>
          <Stack display={"inline-block"} m={"0 auto"}>
            <AssistoButton
              name={t("COMMON.GO_BACK_TO_ASSISTO")}
              buttonVarient={ButtonVarient.Contained}
              startIcon={<ReplyIcon />}
              handleBtnClick={() =>
                (window.location.href = `${assisto_qa_page}/verify-email`)
              }
            />
          </Stack>
        </Stack>
      </ThemeProvider>
  );
}

export default ErrorScreen;
