import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

export interface IAssistoTextFiled {
  required?: boolean;
  label: string;
  value?: string;
  helperText?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: boolean;
  id?: string;
  name?: string;
  inputProps?: IInputProps;
  isDisabled?: boolean;
}

interface IInputProps {
  label: string;
  maxLength: number;
}

export default function AssistoTextFiled(props: IAssistoTextFiled) {
  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <TextField
        error={props.errorMessage}
        helperText={props.helperText}
        fullWidth
        value={props.value}
        label={
          <React.Fragment>
            {props.label}
            {props.required && (
              <Typography
                component="span"
                variant="body2"
                color={"var(--color-dark-red)"}
              >
                &nbsp;*
              </Typography>
            )}
          </React.Fragment>
        }
        id={props.id}
        name={props.name}
        inputProps={{
          label: props.inputProps?.label,
          maxLength: props.inputProps?.maxLength,
          "data-testid": props.id,
        }}
        onChange={props.handleChange}
        disabled={props.isDisabled}
      />
    </Box>
  );
}
