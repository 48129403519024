import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import JobsIcon from "../../assets/images/job-details.svg";
import { ThemeProvider } from "styled-components";
import theme from "../../widgets/Theme/theme";
import { AssistoTable, Column } from "../../widgets/Table";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";
import { ITableData } from "../Dashboard/index";
import { useEffect, useState } from "react";
import AssistoSearch from "../../widgets/AssistoSearchField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { AssistoButton } from "../../widgets/AssitoButton";
import { AppDispatch } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import AssistoToolTip, { Position } from "../../widgets/AssistoToolTip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { createJdPage, forbiddenPage } from "../Routes/routes";
import { getJobsListPage } from "../../thunks/jobsPageListthunk";
import {
  GetCompleteJobsListData,
  GetTotalJobsData,
} from "../../reducers/jobsPageListreducers";
import { loggedInUserPermissions } from "../../utils/permissionManagement";
import { isDefined } from "../../utils/helper";
import Pagination from "../../widgets/Pagination";
import AvatarChips, { StatusColor } from "../../widgets/AssistoChip";
import { ButtonVarient } from "../../utils/commonEnums";
import ThreeDot from "../../widgets/ThreeDot";

const JdColumns: Column[] = [
  { id: "actions", label: "", minWidth: 50, headerAlign: "right" },
  { id: "id", label: "Job Id", minWidth: 50, headerAlign: "right" },
  { id: "job_title", label: "Job Title", minWidth: 150, headerAlign: "right" },
  {
    id: "matched_profiles",
    label: "Matched Profile",
    minWidth: 80,
  },
  {
    id: "experience",
    label: "Experience",
    minWidth: 100,
  },
  {
    id: "must_have_skills",
    label: "Must have skill",
    minWidth: 150,
  },
  {
    id: "nice_to_have_skills",
    label: "Nice to have skill",
    minWidth: 150,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 80,
  },
  {
    id: "posting_type",
    label: "Posting Type",
    minWidth: 150,
  },
  {
    id: "client_name",
    label: "Client Name",
    minWidth: 100,
  },
  {
    id: "jdAge",
    label: "Created At",
    minWidth: 100,
  },
];

function JobsPage() {
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [rowsPerPageNum, setRowsPerPageNum] = useState<number>(10);

  const { t } = useTranslation(NAMESPACE.RESUMESLIST);
  const dispatch: AppDispatch = useDispatch();
  const CompleteJobsAllData = useSelector(GetCompleteJobsListData);
  const totalJobsCount = useSelector(GetTotalJobsData);
  const navigate = useNavigate();
  const tableData: ITableData[] = [
    {
      title: "HOME_PAGE.JD_TABLE_TITLE",
      tableColumn: JdColumns,
      tableRows: CompleteJobsAllData,
      headerFontSize: "14px",
      defaultMessage: "HOME_PAGE.PLEASE_CREATE_JD",
    },
  ];
  useEffect(() => {
    if (
      isDefined(loggedInUserPermissions) &&
      loggedInUserPermissions.includes("VIEW_JOBS")
    ) {
      dispatch(
        getJobsListPage({
          page: currentPageNum,
          pageSize: Number(rowsPerPageNum),
        })
      );
    } else {
      navigate(forbiddenPage);
    }
  }, [currentPageNum, rowsPerPageNum]);

  const onPageNumberClick = (clickedPageNumber: number) => {
    setCurrentPageNum(clickedPageNumber);
  };

  const onRowsPerPageChange = (value: number) => {
    setRowsPerPageNum(value);
    setCurrentPageNum(1);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box width={"98%"} my={4}>
        <Paper>
          <Stack p={4} direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              <img width={"22%"} src={JobsIcon} alt="resumesIcon" />
              <Stack direction={"column"} width={"100%"}>
                <Typography variant="h5" fontWeight={"var(--font-weight-500)"}>
                  {t("RESUMES_PAGE.JOB_DETAILS")}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={3}>
              <AssistoSearch />
              <AssistoButton
                buttonVarient={ButtonVarient.Contained}
                name={t("RESUMES_PAGE.CREATE_NEW_JD")}
                endIcon={<AddCircleOutlineIcon />}
                handleBtnClick={() => navigate(createJdPage)}
              />
              <FilterAltIcon fontSize="medium" />
            </Stack>
          </Stack>
        </Paper>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack className="hire-assisto__white-bg">
              <AssistoTable
                rows={CompleteJobsAllData?.map((row, index: number) => {
                  const mustHaveSkills = row.jd_skills_details
                    .filter((skill) => skill.ismandatory)
                    .map((skill) => skill.skill);
                  const niceToHaveSkills = row.jd_skills_details
                    .filter((skill) => !skill.ismandatory)
                    .map((skill) => skill.skill);

                  const createdAtDate = new Date(row.jd_details.created_at);
                  const currentDate = new Date();
                  const ageInDays = Math.floor(
                    (currentDate.getTime() - createdAtDate.getTime()) /
                      (1000 * 60 * 60 * 24)
                  );

                  return {
                    actions: <ThreeDot job_details={row.jd_details} />,
                    id: rowsPerPageNum * (currentPageNum - 1) + index + 1,
                    job_title:
                      row.jd_details.job_title.length > 15 ? (
                        <AssistoToolTip
                          position={Position.Top}
                          title={row.jd_details.job_title}
                          content={row.jd_details.job_title
                            .substring(0, 25)
                            .concat("...")}
                        />
                      ) : (
                        row.jd_details.job_title
                      ),
                    matched_profiles: row.matched_profiles,
                    experience: `${row.jd_details.minimum_experience}-${row.jd_details.maximum_experience} years`,
                    must_have_skills:
                      mustHaveSkills.length > 2 ? (
                        <AssistoToolTip
                          position={Position.Top}
                          title={mustHaveSkills.join(" , ")}
                          content={mustHaveSkills
                            .slice(0, 2)
                            .join(" , ")
                            .concat("...")}
                        />
                      ) : (
                        mustHaveSkills.join(" , ")
                      ),
                    nice_to_have_skills:
                      niceToHaveSkills.length > 2 ? (
                        <AssistoToolTip
                          position={Position.Top}
                          title={niceToHaveSkills.join(" , ")}
                          content={niceToHaveSkills
                            .slice(0, 2)
                            .join(" , ")
                            .concat("...")}
                        />
                      ) : (
                        niceToHaveSkills.join(" , ")
                      ),
                    statusName: row.jd_details.status,
                    status: (
                      <AvatarChips
                        color={StatusColor.ACTIVE}
                        label={row.jd_details.status}
                      />
                    ),
                    posting_type: row.jd_details.posting_type.replace("_", " "),
                    client_name: row.jd_details.client_name,
                    jdAge:
                      ageInDays > 0
                        ? `${ageInDays} ${t("RESUMES_PAGE.DAY")}${ageInDays !== 1 ? "s" : ""} ago`
                        : ageInDays === 0
                          ? createdAtDate.getHours() === new Date().getHours() &&
                          createdAtDate.getMinutes() === new Date().getMinutes()
                          ? t("RESUMES_PAGE.JUST_NOW")
                          : t("RESUMES_PAGE.TODAY")
                        : "NA",
                  };
                })}
                columns={JdColumns}
                defaultMessage={t("RESUMES_PAGE.NO_DATA_AVAILABLE")}
                headerCheckBoxNeeded={false}
                headerFontSize={"14px"}
                dataCheckBoxNeeded={true}
                checkBoxDropdownNeeded={false}
                isPaginationRequired={false}
              />
              <Stack my={2}>
                <Pagination
                  totalItems={totalJobsCount}
                  currentPageNumber={currentPageNum}
                  rowsPerPage={rowsPerPageNum}
                  onPageNumberClick={onPageNumberClick}
                  onRowsPerPageChange={onRowsPerPageChange}
                  rowsPerPageList={[10, 25, 50, "All"]}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default JobsPage;
