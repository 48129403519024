import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface IJobsList {
  status_message: string;
  data: IData;
}

export interface IData {
  jobs_details_list: IJobsDetailsList[];
  page: number;
  pageSize: number;
  total_jobs_count: number;
}

export interface IJobsDetailsList {
  jd_details: IJdDetails;
  jd_skills_details: IJdSkillsDetail[];
  matched_profiles: number;
}

export interface IJdSkillsDetail {
  skill: string;
  experience_required_for_skill: number;
  ismandatory: boolean;
  extras: Extras;
  jd_uuid: string;
  is_active: boolean;
  created_at: string;
}

export interface Extras {}

export interface IJdDetails {
  id: number;
  jd_uuid: string;
  posting_type: string;
  job_title: string;
  job_end_date: string;
  job_summary: string;
  job_description: string;
  industry: string;
  category: string;
  roles: string;
  minimum_experience: number;
  maximum_experience: number;
  certified_degree_name: string;
  specialization: string;
  currency: string;
  minimum_salary: number;
  maximum_salary: number;
  contract_joining_date: string;
  contract_duration: string;
  contract_billing_rate: string;
  walkin_city_name: string;
  walkin_start_date: string;
  walkin_end_date: string;
  walkin_start_time: string;
  walkin_end_time: string;
  vacancy: number;
  country: string[];
  state: string[];
  city: string[];
  company_name: string;
  about_company: string;
  name: string;
  phone_number: string;
  email: string;
  apply_online_url: string;
  is_short_jd: boolean;
  template_name: string;
  is_template: boolean;
  status: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  client_name: string;
  job_apply_end_date: string;
  notice_period: string;
}

export interface IJobsListResponse {
  jobsData: IJobsList;
  loading: string;
}

export interface IJobsPageRequest {
  page: number;
  pageSize: number;
}


const getJobsPageListEndPoint =
  "/job-descriptions?page=:page&pageSize=:page_size";

export const getJobsListPage = createAsyncThunk(
  "resumesPage/getResumesPage",
  async (data: IJobsPageRequest, thunkAPI) => {
    const endPoint = getJobsPageListEndPoint
      .replace(":page", data.page.toString())
      .replace(":page_size", data.pageSize.toString());
    try {
      const response = await Get<IJobsList, IJobsPageRequest>(
        endPoint,
        undefined,
        {},
        true
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
