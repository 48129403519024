// dropdown.ts

export interface IMenus {
    label: string;
    value: string;
  }
  
  export const BillingRateItems: IMenus[] = [
    { label: "Per Hour", value: "PER_HOUR" },
    { label: "Per Day", value: "PER_DAY" },
    { label: "Per Week", value: "PER_WEEK" },
    { label: "Per Year", value: "PER_YEAR" },
  ];
  
  export const MenuItems: IMenus[] = [
    { label: "Full Time", value: "Full Time" },
    { label: "Contract", value: "Contract" },
    { label: "Walk In", value: "Walkin Drive" },
  ];
  
  export const RegionItems: IMenus[] = [
    { label: "India", value: "india" },
  ];
  
  export const StatusItems: IMenus[] = [
    { label: "Active", value: "Active" },
    { label: "Hold", value: "Hold" },
    { label: "Inactive", value: "Inactive" },
    { label: "Closed", value: "Closed" },
  ];
  
  export const NoticePeriodItems: IMenus[] = [
    { label: "Immediate", value: "immediate" },
    { label: "Upto 15 days", value: "upto_15_days" },
    { label: "Upto 30 days", value: "upto_30_days" },
    { label: "Upto 45 days", value: "upto_45_days" },
    { label: "Upto 2 months", value: "upto_2_months" },
    { label: "Upto 3 months", value: "upto_3_months" },
    { label: "Upto 6 months", value: "upto_6_months" },
  ];
  