import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExtensionIcon from "@mui/icons-material/Extension";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import { useNavigate } from "react-router-dom";
import { createJdPage } from "../../pages/Routes/routes";
import {
  getJobsListPage,
  IJdDetails,
  IJdSkillsDetail,
  IJobsList,
} from "../../thunks/jobsPageListthunk";

const options = [
  { label: "View Job", icon: <VisibilityIcon /> },
  { label: "Check Match", icon: <ExtensionIcon /> },
  { label: "Download JD", icon: <SaveAltIcon /> },
  { label: "Edit JD", icon: <BorderColorIcon /> },
  { label: "Delete JD", icon: <DeleteIcon /> },
  { label: "Job Posting", icon: <NoteAddIcon /> },
  { label: "Send Refferal", icon: <MobileScreenShareIcon /> },
];

interface JobIdProps {
  job_details?: IJdDetails;
  jd_skills_details?: IJdSkillsDetail[] | null;
}

const ITEM_HEIGHT = 80;

export default function ThreeDot({
  job_details,
  jd_skills_details,
}: JobIdProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (optionLabel: string) => {
    setAnchorEl(null);
    if (optionLabel === "Edit JD") {
      try {
        navigate(createJdPage, { state: { job_details, jd_skills_details } });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <IconButton
        data-testid="more-button"
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "22ch",
            },
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            data-testid={`menu-item-${option.label}`}
            onClick={() => handleClose(option.label)}
          >
            {option.icon && (
              <span style={{ marginRight: 8 }}>{option.icon}</span>
            )}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
