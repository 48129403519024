import dayjs, { Dayjs, isDayjs } from 'dayjs';
import { IEducationData, ILocationInfoResponse, ISpecializationData } from "../../../thunks/homeThunk";

export const mandatoryType = (value: any): boolean => {
    return value !== null && value !== undefined && value !== '';
  };
  

  export const patternFailType = (value: string, pattern: RegExp, required: boolean = true): boolean => {
    return required ? pattern.test(value) : true;
  };
  
  export const validateJobTitle = (title: string): boolean => {
    return mandatoryType(title) && patternFailType(title, /[a-zA-Z0-9 ]+/, true);
  };
  
  export const validateCompanyName = (name: string | null): boolean => {
    if (name === null || name === '') 
      return true;
    return patternFailType(name, /[a-zA-Z0-9 ]+/, true);
  };
  
  export const validateVacancy = (vacancy:string | null): boolean => {
    if (!vacancy) return true;
    return patternFailType(vacancy, /^[1-9][0-9]{0,3}$/, true);
  };
  
  export const validateJobSummary = (summary: string | null): boolean => {
    if(summary ===null) return true;
    return patternFailType(summary, /^[\s\S]{0,2000}$/, true);
  };
  
  export const validateJobDescription = (description: string | null): boolean => {
    if(description ===null) return true;
    return mandatoryType(description) && patternFailType(description, /^[\s\S]{0,5000}$/, true);
  };
  
  export const validateExperience = (fromExp: number, toExp: number): boolean => {
    return (
      mandatoryType(fromExp) &&
      patternFailType(String(fromExp), /^[0-9]+(\.[0-9]{1,2})?$/, true) &&
      mandatoryType(toExp) &&
      patternFailType(String(toExp), /^[0-9]+(\.[0-9]{1,2})?$/, true) &&
      fromExp <= toExp
    );
  };
  
  export const validateSkills = (skills: string[]): boolean => {
    return skills.length > 0;
  };
  
  export const validateSalaryRange = (minSalary: number, maxSalary: number): boolean => {
    return (
      patternFailType(String(minSalary), /^\d+$/, true) &&
      patternFailType(String(maxSalary), /^\d+$/, true) &&
      minSalary <= maxSalary
    );
  };
  
  export const validateCertifiedDegree = (degree: IEducationData | null): boolean => {
    return mandatoryType(degree);
  };
  
  export const validateSpecialization = (specialization: ISpecializationData | null): boolean => {
    return mandatoryType(specialization);
  };

  export const validateCountry = (country: ILocationInfoResponse | null): boolean => {
    return mandatoryType(country);
  }
  
  export const validateContactName = (name: string): boolean => {
    return patternFailType(name, /^[a-zA-Z\s]{1,35}$/, true);
  };
  
  export const validatePhoneNumber = (phone: string | null): boolean => {
    if(phone === null) return true
    return patternFailType(phone, /^[\+\d][\d\s\-]{13,22}$/, true);
  };
  
  export const validateEmail = (email: string): boolean => {
    return patternFailType(email, /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, true);
  };
  
  export const validateAboutCompany = (about: string): boolean => {
    if(about === null) return true;
    return patternFailType(about, /^[a-zA-Z\s]{0,2000}$/, true);
  };
  
  export const validateOnlineUrl = (url: string | null): boolean => {
    if(url === null) return true
    return patternFailType(url, /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, true);
  };
  
  export const validateContractFields = (contractTimeperiod: string, joiningDate: Dayjs | null): boolean => {
    return mandatoryType(contractTimeperiod) && mandatoryType(joiningDate);
  };

  export const validateJobEndDate = (jobEndDate: Dayjs | null): boolean => {
    return (
      mandatoryType(jobEndDate) &&
      isDayjs(jobEndDate) &&
      !jobEndDate.isBefore(dayjs())
  );
  };
  
  export const validateWalkInDriveFields = (
    venue: string | null,
    fromWalkDate: Dayjs | null,
    toWalkDate: Dayjs | null,
    fromTiming: Dayjs | null,
    toTiming:Dayjs | null,
    jobEndDate: Dayjs | null
  ): boolean => {
    return (
      mandatoryType(venue) &&
      mandatoryType(fromWalkDate) &&
      mandatoryType(toWalkDate) &&
      mandatoryType(fromTiming) &&
      mandatoryType(toTiming) &&
      fromTiming !== null &&
        toTiming !== null &&
        fromTiming.isBefore(toTiming)  &&
        validateJobEndDate(jobEndDate) 
    );
  };
  
  export const validateTemplateFields = (templateName: string): boolean => {
    return (
      mandatoryType(templateName) &&
      patternFailType(templateName, /^[a-zA-Z0-9_]+$/, true)
    );
  };
  