import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface IResumesList {
  status_message: string;
  data: IData;
}

export interface IData {
  resumes_details_list: IResumesDetailsList[];
  page: number;
  pagesize: number;
  resumes_count: number;
}

export interface IResumesDetailsList {
  id: number;
  resume_store_path: string;
  resume_name: string;
  name_of_candidate: string;
  resume_skill_list: string[];
  matched_jds: string[];
  current_designation: string;
  total_years_of_experience: string;
  working_domain: string;
  qualification: string;
  speaking_language: ISpeakingLanguage;
  github_link: string;
  linkedin_link: string;
  certification: ICertification;
  awards: IAwards;
  date_of_birth: string;
  mobile_number: string[];
  email: string;
  current_company: string;
  salary: number;
  current_location: string;
  preferred_location: string;
  notice_period: string;
  availability_for_interview: string;
  uploaded_at: string;
  extracted_at: string;
  updated_at: string;
  job_source: string;
  extras: IExtras;
}

export interface ISpeakingLanguage {}

export interface ICertification {}

export interface IAwards {}

export interface IExtras {}

export interface IResumesPageRequest {
  page: number;
  pageSize: number;
}

const getresumesPageListEndPoint = "resumes?page=:page&pageSize=:page_size";

export const getresumesPage = createAsyncThunk(
  "resumesPage/getResumesPage",
  async (data: IResumesPageRequest, thunkAPI) => {
    try {
      const endPoint = getresumesPageListEndPoint
        .replace(":page", data.page.toString())
        .replace(":page_size", data.pageSize.toString());
      const response = await Get<IResumesList, IResumesPageRequest>(
        endPoint,
        undefined,
        {},
        true
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
