import { Post, Get, Put } from "../utils/apiServices";
import {
  setCategoryInfo,
  setIndustryInfo,
  setRolesInfo,
  setCountryInfo,
  setEducationDegreeInfo,
  setSpecializationInfo,
  setSkillInfo,
  setCurrencyInfo,
  setStateInfo,
  setCityInfo,
  setClientInfo,
} from "../reducers/homeReducer";

/*
 * purpose: This file defines Thunk actions for handling API calls related to the Home Module in the application.
 * Each thunk function is responsible for making asynchronous API requests and dispatching the results
 * to the Redux store. The functions include:
 * author: Aayush Chourasia
 * version: 1.0
 * lastUpdatedAt: 28-10-2024
 */

// --- Job Description Interfaces ---
export interface IcreateJdResponse {
  status_message: string;
  data: IcreateJdData;
}
export interface IcreateJdData {
  id: number;
  bg_task_id: number;
  create_status: string;
}
// --- createJd Payload Interfaces ---
// --- createJd Payload Interfaces ---
export interface IcreateJdPayload {
  jd_details: IJdDetails;
  jd_skills_details: Jdskillsdetail[];
}
export interface Jdskillsdetail {
  skill: string;
  ismandatory: boolean;
}
export interface IJdDetails {
  posting_type: string;
  job_title: string;
  job_end_date: string | null | undefined;
  job_summary: string | null | undefined;
  job_description: string | null | undefined;
  industry: number | null | undefined;
  category: string | null | undefined;
  roles: string | null | undefined;
  minimum_experience: number;
  maximum_experience: number;
  certified_degree_name: string | null | undefined;
  specialization: number | null | undefined;
  currency: string | undefined | null;
  minimum_salary: number | null;
  maximum_salary: number | null;
  contract_joining_date: string | null | undefined;
  contract_duration: string | null;
  contract_billing_rate: string | null;
  walkin_city_name: string | null;
  walkin_start_date: string | null | undefined;
  walkin_end_date: string | null | undefined;
  walkin_start_time: string | null | undefined;
  walkin_end_time: string | null | undefined;
  notice_period: string | null;
  vacancy: string | null;
  country: string[] | null;
  state: string[] | null;
  city: string[] | null;
  company_name: string | null | undefined;
  about_company: string | null | undefined;
  name: string | null;
  phone_number: string | null;
  email: string | null;
  apply_online_url: string | null | undefined;
  is_short_jd: boolean;
  template_name: string | null;
  is_template: boolean;
  created_at: string;
  status: string;
  client_name: string | null | undefined;
  job_apply_end_date: string | null;
}

// --- Client Name Interfaces ---
export interface IClientDetail {
  id: number;
  clientName: string;
  clientCode: string;
  country: string;
  currency: string;
  invoiceEmail: string;
  status: string;
  numberOfProjects: number;
}

export interface IClientData {
  totalClients: number;
  client: IClientDetail[];
}
export interface IClientInfoResponse {
  status: string;
  data: IClientData;
}

// --- Industry Interfaces ---
export interface IIndustryResponse {
  status_message: string;
  data: IIndustryData[];
}
export interface IIndustryData {
  id: number;
  industry_name: string;
}

// --- Category Interfaces ---
export interface ICategoryResponse {
  status_message: string;
  data: ICategoryData[];
}

// --- Roles Interfaces ---
export interface IRolesResponse {
  status_message: string;
  data: IRolesData[];
}
export interface IRolesData {
  id: string;
  role_name: string;
}
export interface ICategoryData {
  id: string;
  category_name: string;
}

// --- Country Interfaces ---
export interface ILocationInfoResponse {
  id: string;
  name: string;
}

// --- State Interfaces ---
export interface IStateInfoResponse {
  id: string;
  name: string;
}

// --- City Interfaces ---
export interface ICityInfoResponse {
  id: string;
  name: string;
}
interface ICitylocation {
  stateId: string;
  countryId: string;
}

// --- Education Interfaces ---
export interface IEducationDegreeResponse {
  status_message: string;
  data: IEducationData[];
}
export interface IEducationData {
  id: string;
  education_degree_name: string;
}

// --- Specialization Interfaces ---
export interface ISpecializationResponse {
  status_message: string;
  data: ISpecializationData[];
}
export interface ISpecializationData {
  id: number;
  specialization_name: string;
}

// --- Skill Interfaces ---
export interface ISkillResponse {
  status_message: string;
  data: ISkillData[];
}
export interface ISkillData {
  id: string;
  skill: string;
  created_at: string;
}

// --- SubSkill Interfaces ---
export interface ISubSkillResponse {
  status_message: string;
  data: ISubSkillData[];
}
export interface ISubSkillData {
  id: string;
  subskill: string;
  search_type: string;
  skill_id: string;
  created_at: string;
}

// --- Currency Interfaces ---
export interface ICurrencyResponse {
  cc: string;
}

// --- Job Update Interfaces ---
export interface IJobStatusUpdateResponse {
  status_message: string;
  data: IData;
}

export interface IData {
  id: number;
  message: string;
}

export interface IJobUpdateRequest {
  id: number;
  status: string;
}

// --- Endpoints ---
const domain_name = "india";
const CreateJdEndpoint = "job-descriptions";
const IndustryEndPoint = `job_portal/industries?domain_name=${domain_name}`;
const CategoryEndPoint = `job_portal/categories?domain_name=${domain_name}`;
const RolesEndPoint = (catId: number) =>
  `job_portal/categories/${catId}/roles?domain_name=${domain_name}`;
const EducationDegreeEndPoint = `job_portal/degrees?domain_name=${domain_name}`;
const SpecializationEndPoint = (degreeId: number) =>
  `job_portal/degrees/${degreeId}/specializations?domain_name=${domain_name}`;
const GetSkillEndpoint = `skills`;
const CountryLocationEndPoint = `https://openapis.nspenvision.com/location/?fields=id,name`;
const StateLocationEndPoint = (countryId: string) =>
  `https://openapis.nspenvision.com/location/?fields=id,name&countryId=${countryId}`;
const CityLocationEndPoint = (stateId: string, countryId: string) =>
  `https://openapis.nspenvision.com/location/?fields=id,name&countryId=${countryId}&stateId=${stateId}`;
const currencyEndPoint = `https://openapis.nspenvision.com/currencies/?fields=cc`;
const updateJobStatusEndPoint = "/job-descriptions/status/id?status=:status";
// const ClientNameEndPointUrl = (userDomain:string | null) => 
// `https://${userDomain}.test.devassisto.com/client/client/all?page=1&size=10`;

const ClientNameEndPointUrl = `client/all?page=1&size=10`;

// --- Thunks ---
export const createJdThunk = async (payload: IcreateJdPayload) => {
  /*
   * - `createJdThunk`: Sends a POST request to create a job description and returns the response.
   */
  try {
    const response = await Post<IcreateJdResponse, IcreateJdPayload>(
      CreateJdEndpoint,
      payload
    );
    return { success: true, response };
  } catch (error) {
    return { success: false, error };
  }
};

export const IndustryThunkToken = () => async (dispatch: any) => {
  /*
   * - `IndustryThunkToken`: Fetches industry data from the API and dispatches it to the store.
   */
  const IndustryEndPointUrl = IndustryEndPoint;
  try {
    const response = await Get<IIndustryResponse, undefined>(
      IndustryEndPointUrl,
      undefined,
      {},
      false
    );
    dispatch(setIndustryInfo(response));
  } catch (error) {
    throw error;
  }
};

export const CategoryThunkToken = () => async (dispatch: any) => {
  /*
   * - `CategoryThunkToken`: Fetches category data from the API and dispatches it to the store.
   */
  try {
    const response = await Get<ICategoryResponse, undefined>(
      CategoryEndPoint,
      undefined,
      {},
      false
    );
    dispatch(setCategoryInfo(response));
  } catch (error) {
    throw error;
  }
};

export const RolesThunkToken = (catId: number) => async (dispatch: any) => {
  /*
   * - `RolesThunkToken`: Fetches role data based on category ID and dispatches it to the store.
   */
  const RolesEndPointUrl = RolesEndPoint(catId);
  try {
    const response = await Get<IRolesResponse, undefined>(
      RolesEndPointUrl,
      undefined
    );
    dispatch(setRolesInfo(response));
  } catch (error) {
    throw error;
  }
};

export const EducationDegreeToken = () => async (dispatch: any) => {
  /*
   * - `EducationDegreeToken`: Fetches education degree data from the API and dispatches it to the store.
   */
  const EducationDegreeEndPointUrl = EducationDegreeEndPoint;
  try {
    const response = await Get<IEducationDegreeResponse, undefined>(
      EducationDegreeEndPointUrl,
      undefined,
      {},
      false
    );
    dispatch(setEducationDegreeInfo(response));
  } catch (error) {
    throw error;
  }
};

export const SpecializationThunk =
  (degreeId: number) => async (dispatch: any) => {
    /*
     * - `SpecializationThunk`: Fetches specialization data based on degree ID and dispatches it to the store.
     */
    const SpecializationEndPointUrl = SpecializationEndPoint(degreeId);
    try {
      const response = await Get<ISpecializationResponse, undefined>(
        SpecializationEndPointUrl,
        undefined
      );
      dispatch(setSpecializationInfo(response));
    } catch (error) {
      throw error;
    }
  };

export const GetSkillThunk = () => async (dispatch: any) => {
  /*
   * - `GetSkillThunk`: Fetches skill data from the API and dispatches it to the store.
   */
  try {
    const response = await Get<ISkillResponse, undefined>(
      GetSkillEndpoint,
      undefined
    );
    dispatch(setSkillInfo(response));
  } catch (error) {
    throw error;
  }
};

export const CurrencyTokenApi = () => async (dispatch: any) => {
  /*
   * - `CurrencyTokenApi`: Fetches currency data from the API and dispatches it to the store.
   */
  try {
    const response = await Get<ICurrencyResponse[], undefined>(
      currencyEndPoint,
      undefined,
      {},
      true
    );
    dispatch(setCurrencyInfo(response));
  } catch (error) {
    throw error;
  }
};

export const CountryLocationToken = () => async (dispatch: any) => {
  /*
   * - `CountryLocationToken`: Fetches country location data from the API and dispatches it to the store.
   */
  try {
    const response = await Get<ILocationInfoResponse[], undefined>(
      CountryLocationEndPoint,
      undefined,
      {},
      false
    );
    dispatch(setCountryInfo(response));
  } catch (error) {
    throw error;
  }
};

export const StateLocationToken =
  (countryId: string) => async (dispatch: any) => {
    /*
     * - `StateLocationToken`: Fetches state location data based on country ID and dispatches it to the store.
     */
    const StateLocationEndPointUrl = StateLocationEndPoint(countryId);
    try {
      const response = await Get<IStateInfoResponse[], undefined>(
        StateLocationEndPointUrl,
        undefined
      );
      dispatch(setStateInfo(response));
    } catch (error) {
      throw error;
    }
  };

export const CityLocationToken =
  (stateAndCountryData: ICitylocation) => async (dispatch: any) => {
    /*
     * - `CityLocationToken`: Fetches city location data based on state and country IDs and dispatches it to the store.
     */
    const CityLocationEndPointUrl = CityLocationEndPoint(
      stateAndCountryData.stateId,
      stateAndCountryData.countryId
    );
    try {
      const response = await Get<ICityInfoResponse[], undefined>(
        CityLocationEndPointUrl,
        undefined
      );
      dispatch(setCityInfo(response));
    } catch (error) {
      return error;
    }
  };

export const updateJobStatus = async (payload: IJobUpdateRequest) => {
  /*
   * - `updateJobStatus`: Sends a PUT request to update the status of a job description and returns the response.
   */
  const endPoint = updateJobStatusEndPoint
    .replace("id", payload.id.toString())
    .replace(":status", payload.status.toString());

  try {
    const response = await Put<IJobStatusUpdateResponse, IJobUpdateRequest>(
      endPoint,
      payload,
      undefined,
      true
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const ClientNameToken = () => async (dispatch: any) => {
  try {
    const companyDomainName = sessionStorage.getItem("domain");
    // const endpoint = ClientNameEndPointUrl(companyDomainName)
    const headers = {
      "Companydomainname": companyDomainName,
    };
    const response = await Get<IClientInfoResponse, undefined>(
      ClientNameEndPointUrl,
      undefined,
      {
        headers: headers
      },
      false
    );
    dispatch(setClientInfo(response));
  } catch (error) {
    throw error;
  }
};
