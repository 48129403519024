import Chip from "@mui/material/Chip";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { ISkillData } from "../../thunks/homeThunk";
import { Ref, SyntheticEvent} from "react";
import React from "react";
import { Typography } from "@mui/material";


/*
 * purpose: A reusable multi-select input field for selecting skills, which integrates MUI's Autocomplete and Chip components
 * author: Aayush Chourasia
 * version: 1.0
 * lastUpdatedAt: 24-10-2024
 */

export interface IAssistoCustomAddInputField {
  required?: boolean;
  id?:string;
  label: string;
  placeholder: string;
  skillsList?: ISkillData[];
  error?: boolean;
  helperText?: string;
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => void;
  value: string[]
}



export default function AssistoCustomAddInputField(
  props: IAssistoCustomAddInputField
) {
  const { skillsList } = props;

  

  return (
    <Stack>
      <Autocomplete
        multiple
        id="tags-filled"
        options={(skillsList || []).map((option) => option.skill)}
        freeSolo
        value={props.value}
        renderTags={(value: readonly string[], getTagProps) => 
          value.map((option: string, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
            <Chip
              key={key}
              variant="outlined"
              label={option}
              {...tagProps}
            />
          );
        })
      }
        
        onChange={props.onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            id={props.id}
            label={
              <React.Fragment>
                {props.label}
                {props.required && (
                  <Typography
                    component="span"
                    variant="body2"
                    color={"var(--color-dark-red)"}
                  >
                    &nbsp;*
                  </Typography>
                )}
              </React.Fragment>
            }
            placeholder={props.placeholder}
            error={props.error}
            helperText={props.helperText}
          />
        )}
      />
    </Stack>
  );
}
