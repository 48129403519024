import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userInfo from "../reducers/userInfoReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import uploadFiles from "../reducers/uploadResumesReducer";
import totalFiles from "../reducers/totalResumesCountReducer";
import uploadProgress from "../reducers/uploadProgressStatusReducer";
import resumesList from "../reducers/resumesPageListReducer";
import jobsListInfo from "../reducers/jobsPageListreducers";
import homeReducer from "../reducers/homeReducer";
import updateJdReducer from "../reducers/updateJdReducer";


/*
 * purpose: The file holds the global state for all the APIs used across the application
 * author: Aayush Chourasia
 * version: 1.0
 * lastUpdatedAt: 28-10-2024
 */

const persistConfigLogin = {
  key: "userInfo",
  storage,
  blacklist: ["extras"],
};

const uploadResumesConfig = {
  key: "files",
  storage,
  blacklist: ["extras"],
};

const totalResumeCountConfig = {
  key: "count",
  storage,
  blacklist: ["extras"],
};

const UploadProgressStatus = {
  key: "uploadStatus",
  storage,
  blacklist: ["extras"],
};

const getResumesList = {
  key: "getResumesData",
  storage,
  blacklist: ["extras"],
};

const getjobsPageList = {
  key: "getJobsPageList",
  storage,
  blacklist: ["extras"],
};
const homeInfo = {
  key: "homeInfo",
  storage,
  blacklist: ["extras"],
};

const rootReducer = combineReducers({
  loginInfo: persistReducer(persistConfigLogin, userInfo),
  resumesInfo: persistReducer(uploadResumesConfig, uploadFiles),
  totalResumesInfo: persistReducer(totalResumeCountConfig, totalFiles),
  uploadProgressStatus: persistReducer(UploadProgressStatus, uploadProgress),
  getAllResumeslist: persistReducer(getResumesList, resumesList),
  getAlljobsPageList: persistReducer(getjobsPageList, jobsListInfo),
  getHomeInfo: persistReducer(homeInfo, homeReducer),
  updateJd: updateJdReducer,
  
  //add more reducer here
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
