import { Translate } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";
import { ThemeProvider } from "@emotion/react";
import theme from "../Theme/theme";

function LoadingPage() {
  const { t } = useTranslation(NAMESPACE.COMMON);

  return (
      <ThemeProvider theme={theme}>
        <Stack className="page-load-container">
          <Box
            sx={{ margin: "0 auto" }}
            className="page-load-container__page-loader"
          ></Box>
          <Typography mt={3} textAlign={"center"} variant="h2">
            {t("COMMON.LOADING_MESSAGE")}
          </Typography>
        </Stack>
      </ThemeProvider>
  );
}

export default LoadingPage;
