import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { Typography } from "@mui/material";

export interface IMenus {
  label: string;
  value: string;
}

export interface IDropDownItems {
  placeholder: string;
  value: string | undefined;
  label?: string;
  id: string;
  menuItems: any[];
  handleChange: (
    e: SelectChangeEvent<string>,
    id: string,
    isMandatory: boolean
  ) => void;
  required?: boolean;
  isDisabled?: boolean;
  isMandatory?: boolean;
}

export default function DropDownitems(props: IDropDownItems) {
  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel>{props.placeholder}</InputLabel>
        <Select
          id={props.id}
          value={props.value}
          label={props.label}
          onChange={(e) =>
            props.handleChange(e, props.id, props.isMandatory ?? false)
          }
          disabled={props.isDisabled}
        >
          {props.menuItems &&
            props.menuItems.length > 0 &&
            props.menuItems.map((menu) => (
              <MenuItem key={menu.value} value={menu.value}>
                {menu.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
