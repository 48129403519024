import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface IUserInfoResponse {
  username: string;
  user_email: string;
  company_domain_name: string;
  role: string;
  privileges: string[];
}
const UserInfoEndpoint = `/assisto/users`;

export const callVerifyToken = createAsyncThunk(
  "login/userInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await Get<IUserInfoResponse, undefined>(
        UserInfoEndpoint,
        undefined
      );
      sessionStorage.setItem("UserInfo", JSON.stringify(response));
      sessionStorage.setItem("domain", response.company_domain_name);
      sessionStorage.setItem("privileges",JSON.stringify(response.privileges));
      return response;
    } catch (error) {
      throw error;
    }
  }
);
