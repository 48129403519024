import { Height } from "@mui/icons-material";
import { CSSProperties } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


interface ITextEditor {
style: CSSProperties;
value?: string;
onChange: (content: string) => void;
placeholder: string;
id: string;
}

const modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ color: [] }, { background: [] }],
    ["link", "image", "attachment"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
  ],
};

const formats = [
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "color",
  "background",
  "link",
  "image",
  "align",
  "attachment",
];



const TextEditor = (props: ITextEditor) => {

  return (
    <>
      <ReactQuill
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        id={props.id}
        modules={modules}
        formats={formats}
        style={props.style}
      />
    </>
  );
};
export default TextEditor;