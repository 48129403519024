import { createAsyncThunk } from "@reduxjs/toolkit";
import { Put } from "../utils/apiServices";

export interface IUpdateJdResponse{
    status_message : string;
    data: IUpdateJdData;

}

export interface IUpdateJdData{
    id: number;
    update_status: string;
}

const UpdateJdEndPoint =(jd_id:number)=>`job-descriptions/data/${jd_id}`;

export const UpdateJdThunk = createAsyncThunk<IUpdateJdResponse, {jd_id:number; payload:any}>(
    "updateJd/updateJdFile",
    async ({ jd_id, payload }) => {
        try {
            const response = await Put<IUpdateJdResponse, any>(UpdateJdEndPoint(jd_id), payload);
            return response;
        } catch (error) {
            throw error;
        }
    }
);