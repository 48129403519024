export const handleSelectChangeUtil = (
    field: string,
    value: string,
    data: Array<any>,
    matchProperty: string,
    thunkToken?: any 
  ) => {
    const selectedItem = data.find(item => item[matchProperty] === value);
    
    return {
      [field]: selectedItem || null,
      ...(thunkToken && { thunkAction: thunkToken }) 
    };
  };
  