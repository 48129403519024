import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { Typography } from "@mui/material";

interface ItimePickers {
  required?: boolean;
  value: Dayjs | null;
  id: string;
  label: string;
  onTimePickChange: (newTime: Dayjs | null, id: string) => void;
  error?: boolean;
  helperText?: string;
  minTime?: Dayjs | undefined;
}

export default function AssistoTimePicker(props: ItimePickers) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["TimePicker"]}>
        <TimePicker
          value={props.value}
          sx={{ width: "100%" }}
          label={
            <React.Fragment>
              {props.label}
              {props.required && (
                <Typography
                component="span"
                variant="body2"
                color={"var(--color-dark-red)"}
                >
                  &nbsp;*
                </Typography>
              )}
            </React.Fragment>
          }
          onChange={(newTime) => props.onTimePickChange(newTime, props.id)}
          slotProps={{
            textField: {
              error: props.error,
              helperText: props.helperText,
            },
          }}
          minTime={props.minTime}
          ampm={false}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
