import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";

import FmdGoodIcon from "@mui/icons-material/FmdGood";
import AssistantIcon from "@mui/icons-material/Assistant";

import theme from "../../../widgets/Theme/theme";
import { ThemeProvider } from "styled-components";

import AssistoTextFiled from "../../../widgets/AssistoTextField";
import AssistoCustomAddInputField from "../../../widgets/AssistoCustomAddInputField";
import { AssistoButton } from "../../../widgets/AssitoButton";
import SnackBar, { Status, Variant } from "../../../widgets/Toaster";
import TextEditor from "../../../widgets/TextEditor";

import {
  alphaNumericWithSpaceOnlySpecialCharPattern,
  alphabetsNumbersWithDotAndHashAndSpace,
  alphabetsWithFiveThousandChar,
  experienceFieldWithDecimalChar,
} from "../../../utils/regExp";

import { NAMESPACE } from "../../../utils/i18nUtils";
import { SHORT_JD } from "../../../utils/constants";
import { ButtonVarient } from "../../../utils/commonEnums";
import { Errors, Item } from "../../../utils/commonInterfaces";

import { AppDispatch } from "../../../store/store";
import {
  ISkillData,
  ILocationInfoResponse,
  IStateInfoResponse,
  ICityInfoResponse,
  CityLocationToken,
  StateLocationToken,
  createJdThunk,
} from "../../../thunks/homeThunk";

import { skillData, cityData, stateData, countryData } from "../../../reducers/homeReducer";
import { jobsPage } from "../../Routes/routes";
import { initialCreateJdState } from "../Components/initialvalue";
import { mandatoryType, validateExperience, validateJobDescription, validateJobTitle } from "../Components/validation";

/*
 * purpose: Creating Short Job Description
 * author: Aayush Chourasia
 * version: 1.0
 * lastUpdatedAt: 24-10-2024
 */

interface Ifields {
  job_posting_type: string;
  jobTitleField: string;
  status: string;
  jobDescription: string | null;
  fromExpField: number;
  toExpField: number;
  country: ILocationInfoResponse | null;
  state: IStateInfoResponse | null;
  city: ICityInfoResponse | null;
  mustHaveSkill: string[];
  goodToHaveSkill: string[];
}

function ShortJobDescription() {

  const shortJdInitialData = {
    job_posting_type: SHORT_JD,
    jobTitleField: "",
    status: "Active",
    jobDescription: null,
    fromExpField: 0,
    toExpField: 0,
    country: null,
    state: null,
    city: null,
    mustHaveSkill: [],
    goodToHaveSkill: [],
  };

  const initialErrorData = {
    jobTitleField: {
      mandatory: false,
      patternFail: false,
    },
    fromExpField: {
      mandatory: false,
      patternFail: false,
      lessThan: false,
    },
    toExpField: {
      mandatory: false,
      patternFail: false,
      greaterThan: false,
    },
    mustHaveSkill: {
      mandatory: false,
      patternFail: false,
    },
    jobDescription: {
      patternFail: false,
    },
    country: {
      mandatory: false,
    },
  };

  
  const [createJdPage, setCreateJdPage] = useState(initialCreateJdState);    
  const location = useLocation()
    const jobDetails= location.state
  const [errors, setErrors] = useState<Errors>(initialErrorData);
  const [selectedSkillData, setSelectedSkillData] = useState<ISkillData[]>();
  const [toaster, setToaster] = useState({
    open: false,
    message: "",
    status: Status.Success,
    variant: Variant.Filled,
  });
  const { t } = useTranslation(NAMESPACE.CREATE_JD);
  const dispatch: AppDispatch = useDispatch();
  const skillsItemData: ISkillData[] = useSelector(skillData);
  const countryItems: ILocationInfoResponse[] = useSelector(countryData);
  const stateItems: IStateInfoResponse[] = useSelector(stateData);
  const cityItems: ICityInfoResponse[] = useSelector(cityData);
  const navigate = useNavigate();

  useEffect(() => {
    const goodToHaveSkills = skillsItemData.filter(
      (skill: any) => !createJdPage.mustHaveSkill.includes(skill.skill)
    );
    setSelectedSkillData(goodToHaveSkills);
  }, [createJdPage.mustHaveSkill]);

  const handleFieldChange = (
    e: SelectChangeEvent<string> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>,
    id: string,
    isMandatory: boolean,
    regex?: RegExp,
  ) => {
        
    /*
     * handleFieldChange : Updates the state for a specific field when the user types into an input or textarea.
     * It validates the input against mandatory requirements and a provided regex pattern, as well as checking
     * if the value meets certain numerical conditions related to experience and salary.
     */

    const value = e.target.value;
    setCreateJdPage((prevValue) => ({
      ...prevValue,
      [id]: value,
    }));
    
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        mandatory: isMandatory ? value === "" : false,
        patternFail: value !== "" ? !regex?.test(value) : false,
        greaterThan: Number(value) < createJdPage?.fromExpField,
      },
    }));
  };

  
 
  
  const handleTextEditor = (
    content:string,
    id: string,
    isMandatory: boolean,
    regex?: RegExp
  ) => {
    /*
     * handleTextEditor : Similar to `handleFieldChange`, but specifically for a text editor component.
     * It updates the state with the editor's content and performs the same validations as above.
     */
    setCreateJdPage((prevState)=>({
      ...prevState,
      [id]: content,
      }))

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        mandatory: isMandatory ? content === "" : false,
        patternFail: content !== "" ? !regex?.test(content) : false,
        greaterThan: Number(content) < createJdPage?.fromExpField,
      },
    }));
  };

  const handleSelectChange = <T extends Item>(
    fieldId:string,
    e: SelectChangeEvent<string>,
    data: T[] | undefined,
    itemKey: string,
    dispatchAction?: any 
  ) => {
    /*
     * handleSelectChange : Manages changes to select fields. It updates the state with the selected item and,
     * if applicable, dispatches actions to update related data (e.g., cities based on selected states).
     */
    const selectedValue = e.target.value;
    let selectedItem:any= data?.find((d:any)=>d[itemKey] === selectedValue)     
      if (selectedItem !== null && dispatchAction) {
        if (fieldId == "state")
          { dispatch(
            CityLocationToken({
              countryId: createJdPage.country?.id ?? '',
              stateId: selectedItem?.id,
            })
          )
        }
        else{
          dispatch(dispatchAction(selectedItem?.id));
        }
      }
      setCreateJdPage((prevValue: any) => ({
        ...prevValue,
        [fieldId]: selectedItem,
      }));
  };

  const getSkillsValue = (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    /*
     * The function `getSkillsValue` handles changes to the 'must have' skills field:
     * Validates that all selected skills match a specified pattern.
     * If valid, updates the form state for 'must have' skills and clears any errors related to this field.
     * If invalid, sets an error indicating a pattern failure for the 'must have' skills.
     */
    const validSkills = value.filter((skill) =>
      alphabetsNumbersWithDotAndHashAndSpace.test(skill)
    );
    let invalidSkills = value.find((skill) => !alphabetsNumbersWithDotAndHashAndSpace.test(skill));
    const stringArray = value;
    
    if (!invalidSkills) {
      setCreateJdPage((prev) => {
        return {
          ...prev,
          mustHaveSkill: stringArray,
        };
      });
      setErrors({
        ...errors,
        mustHaveSkill: {
          mandatory: false,
          patternFail: false,
        }
    })
    
    } else {
      setCreateJdPage((prev) => ({
        ...prev,
        mustHaveSkill: stringArray,
      }));
      setErrors((prev) => {
        return {
          ...prev,
          mustHaveSkill: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }
  };

  const getGoodToHaveSkillsValue = (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    /*
     * The function `getGoodToHaveSkillsValue` updates the form state for 'good to have' skills:
     * Sets the 'good to have' skills in the form state whenever a new value is selected.
     */
    setCreateJdPage((prev) => {
      return {
        ...prev,
        goodToHaveSkill: value,
      };
    });
  };


  // const mandatoryType = (obj?: unknown, num?: any): boolean => {
  //   /*
  //    * mandatoryType : Utility function that checks if a value is defined, not null, and not an empty string,
  //    * used to validate mandatory fields.
  //    */
  //   return obj !== undefined && obj !== null && obj !== "";
  // };

  const patternFailType = (
    value: string | undefined | null | any,
    regex: RegExp | any,
    isMandatory?: boolean
  ): boolean => {
    /*
     * patternFailType : Validates a value against a regex pattern, considering whether the field is mandatory;
     * if it is mandatory, it checks for a match; otherwise, it always returns true.
     */
    if (isMandatory) {
      return regex.test(value);
    } else {
      return true;
    }
  };

  const handleCreateJdFormValidation = ():boolean =>{
    
    /*
     * handleCreateJdFormValidation : Validates the entire job description form by checking required fields,
     * ensuring they meet specific patterns and constraints (e.g., alphanumeric, experience range, salary range).
     * It also checks for the presence of mandatory fields based on the job posting type (e.g., CONTRACT, WALK_IN_DRIVE)
     * and ensures that time-related fields are in the correct order.
     */

    return(
      mandatoryType(createJdPage.jobTitleField) &&
      patternFailType(
        createJdPage.jobTitleField,
        alphaNumericWithSpaceOnlySpecialCharPattern,
        true
      ) &&
      mandatoryType(createJdPage.fromExpField) &&
      (createJdPage.fromExpField !== null
        ? patternFailType(
            createJdPage.fromExpField,
            experienceFieldWithDecimalChar
          )
        : false) &&
      mandatoryType(createJdPage.toExpField) &&
      (createJdPage.toExpField !== null && createJdPage.fromExpField !== null
        ? patternFailType(
            createJdPage.toExpField,
            experienceFieldWithDecimalChar
          ) || createJdPage.toExpField < createJdPage.fromExpField
        : false) &&
      patternFailType(
        createJdPage.jobDescription,
        alphabetsWithFiveThousandChar,

        false
      ) &&
      createJdPage.mustHaveSkill.length > 0 &&
      mandatoryType(createJdPage.country)
      // validateJobTitle(createJdPage.jobTitleField) &&
      // validateExperience(createJdPage.fromExpField, createJdPage.toExpField) &&
      // validateJobDescription(createJdPage.jobDescription) 
    )
  };

  const handleCreateJdFormData = () =>{
    
    /*
     * handleCreateJdFormData : Constructs and returns an object containing the job description data,
     * formatted for submission. It aggregates skill data, formats dates, and handles conditional fields
     * based on the provided job posting type and other criteria.
     */
    const skillsData = createJdPage.mustHaveSkill
        .map((skill) => {
          return {
            skill: skill,
            ismandatory: true,
          };
        })
        .concat(
          createJdPage.goodToHaveSkill.map((skill) => {
            return {
              skill: skill,
              ismandatory: false,
            };
          })
        );

      const FormData = {
        jd_details: {
          posting_type: SHORT_JD,
          job_title: createJdPage.jobTitleField,
          job_end_date: null,
          job_summary: null,
          job_description: createJdPage.jobDescription,
          industry: null,
          category: null,
          roles: null,
          minimum_experience: Number(createJdPage.fromExpField),
          maximum_experience: Number(createJdPage.toExpField),
          certified_degree_name: null,
          specialization: null,
          currency: null,
          minimum_salary: null,
          maximum_salary: null,
          contract_joining_date: null,

          contract_duration: null,
          contract_billing_rate: null,
          walkin_city_name: null,
          walkin_start_date: null,
          walkin_end_date: null,

          walkin_start_time: null,
          walkin_end_time: null,
          notice_period: null,
          vacancy: null,
          country:  createJdPage.country?.name ? [createJdPage.country.name] : [],
          state: createJdPage.state?.name ? [createJdPage.state.name] : [],
          city:createJdPage.city?.name ? [createJdPage.city.name] : [],
          company_name: null,
          about_company: null,
          name: null,
          phone_number: null,
          email: null,
          apply_online_url: null,
          is_short_jd: false,
          template_name: null,
          is_template: false,
          created_at: new Date().toISOString().split('T')[0] ,
          status: createJdPage.status,
          client_name: null,
          job_apply_end_date: null,
        },
        jd_skills_details: skillsData,
      };
      return FormData;
  }


  const handleCreateJdFormErrors = () =>{
    
  /*
   * The function `handleCreateJdFormErrors` validates individual fields of the job description form,
   * setting errors in the state based on the validation.
   */

    if (createJdPage?.jobTitleField === "") {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobTitleField: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    } else if (
      !patternFailType(
        createJdPage?.jobTitleField,
        alphaNumericWithSpaceOnlySpecialCharPattern
      )
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobTitleField: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }
    if (
      createJdPage.jobDescription !== null &&
      !patternFailType(
        createJdPage?.jobDescription,
        alphabetsWithFiveThousandChar
      )
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobDescription: {
            patternFail: true,
          },
        };
      });
    }
    if (createJdPage.fromExpField > createJdPage.toExpField) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          toExpField: {
            mandatory: false,
            patternFail: false,
            greaterThan: true,
          },
        };
      });
    }
    if (createJdPage?.country === null) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          country: {
            mandatory: true,
          },
        };
      });
    }
    if (createJdPage?.mustHaveSkill.length === 0) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          mustHaveSkill: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    } else {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          mustHaveSkill: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    setToaster((toast) => {
      return {
        ...toast,
        open: true,
        message: t("CREATE_JD.PLEASE_FILL_THE_FIELDS"),
        status: Status.Error,
      };
    });
  }

  const handleCreateJd = async () => {
    
  /*
   * The function `handleCreateJd` handles the creation of a job description: 
   * Validates the form data using `handleCreateJdFormValidation`.
   * If validation passes, it prepares the form data with `handleCreateJdFormData` and sends it via `createJdThunk`.
   * If the job description creation is successful, it resets the form, updates the toaster notification to indicate success, and hides the template option.
   * If validation fails, it triggers `handleCreateJdFormErrors` to set errors and updates the toaster notification to indicate failure.
   */

    if (handleCreateJdFormValidation()){
      const FormData = handleCreateJdFormData();
      const result = await createJdThunk(FormData);
      if(result.success){
        setCreateJdPage(initialCreateJdState);
        setToaster((toast) => ({
          ...toast,
          open: true,
          message: t("CREATE_JD.JOB_CREATED_SUCCESSFULLY"),
          status: Status.Success,
        }))
      }
    } else {
      handleCreateJdFormErrors();
      setToaster((toast) => ({
        ...toast,
        open: true,
        message: t("CREATE_JD.FAILED_TO_CREATE_A_JD"),
        status: Status.Error,
      }))
    }
  };

  const handleCancelCreateJd = () => {  
  /*
   * The function `handleCancelCreateJd` resets the job description form and error states,
   * and navigates back to the jobs page.
   */
    setCreateJdPage(initialCreateJdState);
    setErrors(initialErrorData);
    navigate(jobsPage);
  };

  const handleToastClose = () => {
    /*
     * The function `handleToastClose` closes the toaster notification: 
     * Updates the toaster state to mark it as closed and clears the message.
     */
    setToaster((toast) => {
      return {
        ...toast,
        open: false,
        message: "",
      };
    });
  };
 useEffect(()=>{
  if(jobDetails){
    const JdDetails = jobDetails.job_details 
    const JdSkillDetails = jobDetails.jd_skills_details
    const selectedCountry=  countryItems.find(i=>i.name===JdDetails.country)
    const selectedmusthaveskills= JdSkillDetails?.map((i:any)=>i.ismandatory===true?i.skill:"").filter(Boolean)
    const selectedgoodtohaveskills = JdSkillDetails?.map((i:any)=> i.ismandatory===false?i.skill:null).filter(Boolean)
    setCreateJdPage((prev)=>({
      ...prev,
      jobTitleField:JdDetails.job_title || "",
      fromExpField:JdDetails.minimum_experience ? Number(JdDetails.minimum_experience):0,
      toExpField:JdDetails.maximum_experience|| 0,
        mustHaveSkill:selectedmusthaveskills || [],
        goodToHaveSkill:selectedgoodtohaveskills || [],
        jobDescription:JdDetails.job_description || null,
        country:selectedCountry|| null,

    }))
  }
 }, [jobDetails])
 useEffect(() => {
  console.log("Country:", jobDetails?.job_details?.country);
  if (jobDetails!==undefined && countryItems.length>0) {
    handleSelectChange(
      "country",
      { target: { id: "country", name: "country", value: jobDetails?.job_details?.country } } as SelectChangeEvent<string>,
      countryItems,
      "name", 
      StateLocationToken
    );
  }
}, [countryItems, jobDetails]);
useEffect(() => {
  if (jobDetails!==undefined && jobDetails?.job_details.state) {
    handleSelectChange(
      "state",
      {
        target: {
          id: "state",
          name: "state",
          value: jobDetails?.job_details?.state,
        },
      } as SelectChangeEvent<string>,
      stateItems,
      "name",
      CityLocationToken
    );
  }
}, [stateItems, jobDetails]);

useEffect(() => {
  if (jobDetails && createJdPage.state) {
    const selectedCity = cityItems.find(city => city.name === jobDetails.job_details.city);
    if (selectedCity) {
      setCreateJdPage((prev) => ({
        ...prev,
        city: selectedCity,
      }));
    }
  }
}, [jobDetails, cityItems, createJdPage.state]);

  return (
      <ThemeProvider theme={theme}>
        <SnackBar {...toaster} handleClose={handleToastClose} />
        <Paper sx={{ py: 4 }}>
          <Grid container px={3.5} gap={3}>
            <Grid item xl={12} lg={12} md={12}>
              <Typography fontSize={"var(--font-size-sm)"}>
                <span style={{ color: "var(--color-dark-red)" }}>
                  {t("CREATE_JD.NOTE")}:
                </span>{" "}
                {t("CREATE_JD.THIS_IS_FOR_INTERNAL_USE_ONLY")}
              </Typography>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={6} sm={12}>
              <AssistoTextFiled
                label="Job Title"
                required
                id="jobTitleField"
                value={createJdPage.jobTitleField}
                errorMessage={
                  errors?.jobTitleField?.mandatory ||
                  errors?.jobTitleField?.patternFail
                }
                helperText={
                  errors?.jobTitleField?.mandatory
                    ? t("CREATE_JD.PLEASE_ENTER_JOB_TITLE")
                    : errors?.jobTitleField?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                handleChange={(e) => {
                  handleFieldChange(
                    e,
                    'jobTitleField',
                    true,
                    alphaNumericWithSpaceOnlySpecialCharPattern
                  );
                }}
              />
            </Grid>
            <Grid
              item
              xl={3.5}
              lg={3.5}
              md={12}
              sm={12}
              display={"grid"}
              alignItems={"center"}
            >
              <Grid item xs={12} xl={12} lg={12} sx={{ margin: "6px 0" }}>
                <Grid container alignItems={"center"} gap={2}>
                  <Grid item xs={3} pl={1}>
                    <Typography>
                      {t("CREATE_JD.EXPERIENCE")}
                      <span style={{ marginLeft: "3px", color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={3.5}>
                    <AssistoTextFiled
                      required
                      errorMessage={
                        errors?.fromExpField?.mandatory ||
                        errors?.fromExpField?.patternFail ||
                        errors?.fromExpField?.lessThan
                      }
                      helperText={
                        errors?.fromExpField?.mandatory
                          ? t("CREATE_JD.PLEASE_ENTER_EXPERIENCE")
                          : errors?.fromExpField?.patternFail
                          ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                          : ""
                      }
                      id="fromExpField"
                      label={t("CREATE_JD.FROM")}
                      value={String(createJdPage.fromExpField) ?? ""}
                      handleChange={(e) => {
                        handleFieldChange(
                          e,
                          'fromExpField',
                          true,
                          experienceFieldWithDecimalChar
                        );
                      }}
                    />
                  </Grid>
                  <Grid item lg={0.25}>
                    <hr />
                  </Grid>
                  <Grid item xs={3.5}>
                    <AssistoTextFiled
                      errorMessage={
                        errors?.toExpField?.mandatory ||
                        errors?.toExpField?.patternFail ||
                        errors?.toExpField?.greaterThan
                      }
                      required
                      helperText={
                        errors?.toExpField?.mandatory
                          ? t("CREATE_JD.PLEASE_ENTER_EXPERIENCE")
                          : errors?.toExpField?.patternFail
                          ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                          : errors.toExpField.greaterThan
                          ? t("CREATE_JD.TO_VALUE_SHOULD_BE_GREATER")
                          : ""
                      }
                      id="toExpField"
                      label={t("CREATE_JD.TO")}
                      value={String(createJdPage.toExpField) ?? ""}
                      handleChange={(e) => {
                        handleFieldChange(
                          e,
                          'toExpField',
                          true,
                          experienceFieldWithDecimalChar
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={7.25} lg={7.25} md={12} sm={12}>
              <FormControl
                fullWidth
                error={errors?.jobDescription?.patternFail}
              >
                <TextEditor 
                  onChange={(content)=>{
                    handleTextEditor(
                      content,
                      'jobDescription',
                      false,
                      alphabetsWithFiveThousandChar
                    )
                  }} 
                  placeholder={t("CREATE_JD.JOB_DESCRIPTION")}
                  id="jobDescription"
                  value={createJdPage.jobDescription ?? ""}
                  style={{height:'80px',
                    marginBottom:'50px',
                  }}
                />
                {createJdPage.jobDescription !== "" &&
                  errors?.jobDescription?.patternFail && (
                    <FormHelperText>
                      {t(
                        "CREATE_JD.PLEASE_ENTER_MINIMUM_FIVE_THOUSAND_CHARACTER"
                      )}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <AssistantIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.SKILLS_TITLE")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={7.25} lg={7.25} md={12} sm={12}>
              <AssistoCustomAddInputField
                required
                label={t("CREATE_JD.MUST_HAVE_SKILL")}
                placeholder={t("CREATE_JD.PLEASE_ENTER_SKILL")}
                skillsList={skillsItemData}
                id="mustHaveSkill"
                value={createJdPage.mustHaveSkill}
                onChange={getSkillsValue}
                error={
                  errors?.mustHaveSkill?.mandatory ||
                  errors?.mustHaveSkill?.patternFail
                }
                helperText={
                  errors?.mustHaveSkill?.mandatory
                    ? t("CREATE_JD.PLEASE_ENTER_ATLEAST_ONE_MUST_HAVE_SKILL")
                    : errors?.mustHaveSkill?.patternFail
                    ? t("CREATE_JD.PLEASE_REMOVE_INVALID_DATA")
                    : ""
                }
              />
            </Grid>
            <Grid item xl={7.25} lg={7.25} md={12} sm={12}>
              <AssistoCustomAddInputField
                required={false}
                value={createJdPage.goodToHaveSkill}
                label={t("CREATE_JD.GOOD_TO_HAVE_SKILL")}
                placeholder={t("CREATE_JD.PLEASE_ENTER_SKILL")}
                skillsList={selectedSkillData}
                onChange={getGoodToHaveSkillsValue}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <FmdGoodIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.JOB_LOCATION")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl
                fullWidth
                error={
                  (createJdPage.country === null ||
                    createJdPage.country?.name === "") &&
                  errors?.country?.mandatory
                }
              >
                <InputLabel>{t("CREATE_JD.COUNTRY")}
                <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color={"var(--color-dark-red)"}
                    >
                      &nbsp;*
                    </Typography>
                    </React.Fragment>
                  </InputLabel>
                <Select
                  id="country"
                  value={createJdPage.country?.name ?? ""}
                  label={t("CREATE_JD.COUNTRY")}
                  onChange={(e)=>{
                    handleSelectChange(
                      'country',
                      e,
                      countryItems,
                      'name',
                      StateLocationToken
                    )
                  }}
                  inputProps={{
                    "data-testid": "country",
                  }}
                >
                  {countryItems.map((menu: ILocationInfoResponse) => (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
                {(createJdPage.country === null ||
                  createJdPage.country?.name === "") &&
                  errors?.country?.mandatory && (
                    <FormHelperText>
                      {t("CREATE_JD.PLEASE_SELECT_FIRST_COUNTRY")}*
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.STATE")}</InputLabel>
                <Select
                  id="state"
                  value={createJdPage.state?.name ?? ""}
                  label={t("CREATE_JD.STATE")}
                  onChange={(e)=>{handleSelectChange(
                    'state',
                    e,
                    stateItems,
                    'name',
                    CityLocationToken
                  )}}
                  disabled={!createJdPage.country}
                >
                  {stateItems.map((menu: ILocationInfoResponse) => (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
                {!createJdPage.country && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_FIRST_COUNTRY")}*
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.CITY")}</InputLabel>
                <Select
                  id="city"
                  value={createJdPage.city?.name ?? ""}
                  label={t("CREATE_JD.CITY")}
                  onChange={(e)=>{handleSelectChange(
                    'city',
                    e,
                    cityItems,
                    'name',
                  )}}
                  disabled={!createJdPage.country || !createJdPage.state}
                >
                  {cityItems.map((menu: ILocationInfoResponse) => (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
                {!createJdPage.country && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_COUNTRY_&_STATE")}*
                  </FormHelperText>
                )}
                {createJdPage.country && !createJdPage.state && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_STATE")}*
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Stack direction={"row"} gap={2} mt={2}>
              <AssistoButton
                buttonVarient={ButtonVarient.Contained}
                name="Save"
                handleBtnClick={handleCreateJd}
              />
              <AssistoButton
                buttonVarient={ButtonVarient.Outlined}
                name="Cancel"
                handleBtnClick={handleCancelCreateJd}
              />
            </Stack>
          </Grid>
        </Paper>
      </ThemeProvider>
  );
}

export default ShortJobDescription;
